.connection-modal {
  width: 48rem;
  padding: 1.6rem;
  padding-top: 8.2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  &-illustration {
    position: relative;

    &-animated {
      position: relative;
      border-radius: 16px;
      width: 98px;
      height: 98px;
      overflow: hidden;
      margin-bottom: 2rem;

      &-close {
        position: absolute;
        top: 32px;
        left: 38px;
        width: 20px;
        height: 20px;
        background-color: $rose-600;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        svg {
          color: $white;
        }

        animation: closeAnimation 4s infinite linear alternate;

        @keyframes closeAnimation {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      }

      &-overlay {
        background-color: $rose-600;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;

        animation: overlayAnimation 2s infinite linear alternate;

        @keyframes overlayAnimation {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 0.2;
          }
        }
      }
    }

    &-chip {
      &.missed {
        background-color: $rose-600;
      }

      &.intermittences {
        background-color: $slate-300;
      }

      border-radius: 50%;
      width: 3.2rem;
      height: 3.2rem;
      background-color: $primary-700;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -3px;
      right: -4px;

      svg {
        color: $white;
      }
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    padding: 0 3rem;
  }

  &-actions {
    margin-top: 2.4rem;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

.resfresg-icon-point {
  position: relative;
  &-dot {
    position: absolute;
    width: 7px;
    height: 7px;
    top: 0;
    right: -3px;
    border-radius: 50%;
    background-color: $amber-400;
  }
}