.tag-container {
    width: fit-content;
    border-radius: 0.4rem;
    padding: 0.4rem 0.8rem;
}

.tag-primary {
    background-color: $primary-100;
    p {
        color: $primary-700;
    }
}

.tag-slate {
    background-color: $slate-200-45;
    p {
        color: $slate-500;
    }
}

.tag-rose {
    background-color: $rose-100;
    p {
        color: $rose-700;
    }
}

.tag-amber {
    background-color: $amber-100;
    p {
        color: $amber-700;
    }
}

.tag-green {
    background-color: $green-100;
    p {
        color: $green-700;
    }
}

.tag-indigo {
    background-color: $indigo-100;
    p {
        color: $indigo-700;
    }
}