.refunds {
  height: 100%;


  @include media-breakpoint-down('sm') {
    .table-body-refunds {
      .caption-bold {
        width: 110px !important;
      }
    }
  }

  .refund {
    font-size: 12px;
    color: rgb(89, 89, 89);
    font-weight: 500;
    border-bottom: 1px solid $gray1;

    .title,
    .price {
      color: $title;
      font-weight: 500;
    }

    .title-container {
      font-size: 14px;
    }

    .price {
      font-size: 12px;
    }

    &:hover,
    &.selected {
      background: $gray1;
    }

    &:hover {
      cursor: pointer;
    }
  }
}