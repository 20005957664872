// Btn icon
.btn-icon-default-filled {
  @include button-icon($primary-600,
    $primary-700,
    $primary-800,
    $white,
    transparent);
}

.btn-icon-default-subtle {
  @include button-icon($primary-100,
    $primary-200,
    $primary-300,
    $primary-900,
    transparent);
}

.btn-icon-default-outline {
  @include button-icon(transparent,
    rgba($slate-200, 0.45),
    rgba($slate-200, 0.7),
    $slate-900,
    $slate-300 );
}

.btn-icon-default-ghost {
  @include button-icon(transparent,
    rgba($slate-200, 0.45),
    rgba($slate-200, 0.7),
    default,
    transparent);
}


// Default (primary) buttons
.btn-sm-default-filled {
  @include button-sm($primary-600,
    $primary-700,
    $primary-800,
    $white,
    transparent);
  padding: 0.6rem 1.6rem;
}

.btn-sm-default-subtle {
  @include button-sm($primary-100,
    $primary-200,
    $primary-300,
    $primary-900,
    transparent);
  padding: 0.6rem 1.6rem;
}

.btn-sm-default-outline {
  @include button-sm(transparent,
    rgba($slate-200, 0.45),
    rgba($slate-200, 0.7),
    $slate-900,
    $slate-300 );
  padding: 0.6rem 1.6rem;
}

.btn-sm-default-ghost {
  @include button-sm(transparent,
    rgba($slate-200, 0.45),
    rgba($slate-200, 0.7),
    $primary-600,
    transparent);
  padding: 0.6rem 1.6rem;
}

.btn-md-default-filled {
  @include button-md($primary-600,
    $primary-700,
    $primary-800,
    $white,
    transparent);
  padding: 0.6rem 1.6rem;
}

.btn-md-default-subtle {
  @include button-md($primary-100,
    $primary-200,
    $primary-300,
    $primary-900,
    transparent);
}

.btn-md-default-outline {
  @include button-md(transparent,
    rgba($slate-200, 0.45),
    rgba($slate-200, 0.7),
    $slate-900,
    $slate-300 );
}

.btn-md-default-ghost {
  @include button-md(transparent,
    rgba($slate-200, 0.45),
    rgba($slate-200, 0.7),
    $primary-600,
    transparent);
}

.btn-lg-default-filled {
  @include button-lg($primary-600,
    $primary-700,
    $primary-800,
    $white,
    transparent);
}

.btn-lg-default-subtle {
  @include button-lg($primary-100,
    $primary-200,
    $primary-300,
    $primary-900,
    transparent);
}

.btn-lg-default-outline {
  @include button-lg(transparent,
    rgba($slate-200, 0.45),
    rgba($slate-200, 0.7),
    $slate-900,
    $slate-300 );
}

.btn-lg-default-ghost {
  @include button-lg(transparent,
    rgba($slate-200, 0.45),
    rgba($slate-200, 0.7),
    $primary-600,
    transparent);
}

// Destructive (cancel) buttons
.btn-sm-destructive-filled {
  @include button-sm($rose-600, $rose-700, $rose-800, $white, transparent);
}

.btn-sm-destructive-outline {
  @include button-sm(transparent,
    rgba($slate-200, 0.45),
    rgba($slate-200, 0.7),
    $rose-600,
    $rose-600 );
}

.btn-sm-destructive-ghost {
  @include button-sm(transparent,
    rgba($slate-200, 0.45),
    rgba($slate-200, 0.7),
    $rose-600,
    transparent);
}

.btn-md-destructive-filled {
  @include button-md($rose-600, $rose-700, $rose-800, $white, transparent);
}

.btn-md-destructive-outline {
  @include button-md(transparent,
    rgba($slate-200, 0.45),
    rgba($slate-200, 0.7),
    $rose-600,
    $rose-600 );
}

.btn-md-destructive-ghost {
  @include button-md(transparent,
    rgba($slate-200, 0.45),
    rgba($slate-200, 0.7),
    $rose-600,
    transparent);
}

.btn-lg-destructive-filled {
  @include button-lg($rose-600, $rose-700, $rose-800, $white, transparent);
}

.btn-lg-destructive-outline {
  @include button-lg(transparent,
    rgba($slate-200, 0.45),
    rgba($slate-200, 0.7),
    $rose-600,
    $rose-600 );
}

.btn-lg-destructive-ghost {
  @include button-lg(transparent,
    rgba($slate-200, 0.45),
    rgba($slate-200, 0.7),
    $rose-600,
    transparent);
}

// Contrast (white) buttons
.btn-sm-contrast-filled {
  @include button-sm($white, $white, $white, $slate-900, transparent);
}

.btn-sm-contrast-subtle {
  @include button-sm(rgba($white, 0.1),
    rgba($white, 0.2),
    rgba($white, 0.3),
    $white,
    transparent);
}

.btn-sm-contrast-outline {
  @include button-sm(transparent,
    rgba($white, 0.1),
    rgba($white, 0.2),
    $white,
    rgba($white, 0.3));
}

.btn-sm-contrast-ghost {
  @include button-sm(transparent,
    rgba($white, 0.1),
    rgba($white, 0.2),
    $white,
    transparent);
}

.btn-md-contrast-filled {
  @include button-md($white, $white, $white, $slate-900, transparent);
}

.btn-md-contrast-subtle {
  @include button-md(rgba($white, 0.1),
    rgba($white, 0.2),
    rgba($white, 0.3),
    $white,
    transparent);
}

.btn-md-contrast-outline {
  @include button-md(transparent,
    rgba($white, 0.1),
    rgba($white, 0.2),
    $white,
    rgba($white, 0.3));
}

.btn-md-contrast-ghost {
  @include button-md(transparent,
    rgba($white, 0.1),
    rgba($white, 0.2),
    $white,
    transparent);
}

.btn-lg-contrast-filled {
  @include button-sm($white, $white, $white, $slate-900, transparent);
}

.btn-lg-contrast-subtle {
  @include button-lg(rgba($white, 0.1),
    rgba($white, 0.2),
    rgba($white, 0.3),
    $white,
    transparent);
}

.btn-lg-contrast-outline {
  @include button-lg(transparent,
    rgba($white, 0.1),
    rgba($white, 0.2),
    $white,
    rgba($white, 0.3));
}

.btn-lg-contrast-ghost {
  @include button-lg(transparent,
    rgba($white, 0.1),
    rgba($white, 0.2),
    $white,
    transparent);
}

.full-mobile {
  @include media-breakpoint-down('sm') {
    width: 100%;
  }
}

.custom-btn:has(.button-loader) {
  p {
    opacity: 0;
  }
}

.button-loader {
  width: 16px;
  height: 16px;
  border: 2px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: absolute;

  @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 
}