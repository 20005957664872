.form-filter {
  font-family: "Inter";
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .input-number-wrapper {
    border: 1px solid #cbd5e1;
    border-radius: 5px;
  }

  .input-number-wrapper:focus-within {
    box-shadow: none;
    border: 1px solid $primary;
    outline: none;
  }

  &__fields {
    margin-left: 4.5rem;
    display: flex;
    width: 60rem;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &__selects {
    display: flex;
    width: 40rem;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  input {
    border: 1px solid #cccccc;
    border-radius: 4px;
    max-height: 3rem;
    background: #fff;
    padding: auto;
    font-size: 12px;
    font-weight: normal;
    outline: none;
    color: inherit;
  }

  @include media-breakpoint-down("xs") {
    flex-direction: column;
    width: auto;
    justify-content: center;

    &__fields {
      width: auto;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-left: -15rem;

      input {
        width: 18rem;
      }
    }

    &__selects {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: -2.5rem;
    }
  }
}

.form-filter-tags {
  font-family: "Inter";
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;

  @include media-breakpoint-down("xs") {
    align-items: center;
    justify-content: space-between;

    div {
      flex-direction: column;
      justify-content: center;
    }
  }
}
