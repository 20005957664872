// High Contrast
$white: #ffffff;
$black: #1a1a1a;

// SM Primary Alegra
$primary-50: #eafafa;
$primary-100: #cff2f1;
$primary-200: #b6ece9;
$primary-300: #9be4e0;
$primary-400: #72d5d1;
$primary-500: #30bbb7;
$primary-600: #30aba9;
$primary-700: #299e9c;
$primary-800: #208d8d;
$primary-900: #1a7e7f;

// Neutrals
$slate-50: #f8fafc;
$slate-100: #f1f5f9;
$slate-200: #e2e8f0;
$slate-300: #cbd5e1;
$slate-400: #94a3b8;
$slate-500: #64748b;
$slate-600: #475569;
$slate-700: #334155;
$slate-800: #1e293b;
$slate-900: #0f172a;

// Opacity neutrals
$slate-700-60: rgba(51, 65, 85, 0.6);
$slate-200-70: rgba(226, 232, 240, 0.7);
$slate-200-45: rgba(226, 232, 240, 0.45);

// Indigo
$indigo-50: #eef2ff;
$indigo-100: #e0e7ff;
$indigo-200: #c7d2fe;
$indigo-300: #a5b4fc;
$indigo-400: #818cf8;
$indigo-500: #6366f1;
$indigo-600: #4f46e5;
$indigo-700: #4338ca;
$indigo-800: #4338ca;
$indigo-900: #312e81;

// Green
$green-50: #f0fdf4;
$green-100: #dcfce7;
$green-200: #bbf7d0;
$green-300: #86efac;
$green-400: #4ade80;
$green-500: #22c55e;
$green-600: #16a34a;
$green-700: #15803d;
$green-800: #166534;
$green-900: #14532d;

// Amber
$amber-50: #fffbeb;
$amber-100: #fef3c7;
$amber-200: #fde68a;
$amber-300: #fcd34d;
$amber-400: #fbbf24;
$amber-500: #f59e0b;
$amber-600: #d97706;
$amber-700: #b45309;
$amber-800: #92400e;
$amber-900: #78350f;

// Amber
$rose-50: #fff1f2;
$rose-100: #ffe4e6;
$rose-200: #fecdd3;
$rose-300: #fda4af;
$rose-400: #fb7185;
$rose-500: #f43f5e;
$rose-600: #e11d48;
$rose-700: #be123c;
$rose-800: #9f1239;
$rose-900: #881337;