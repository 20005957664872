.empty-state {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  svg {
    width: 40px;
    height: 40px;
  }
}