.menu-content {
    overflow-y: auto;
    margin-bottom: auto;

    &::-webkit-scrollbar-thumb {
        background-color: $slate-300;
    }

    ul {
        padding: 0.8rem 0;
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        margin: 0;
        list-style: none;

        li {
            padding: 0 0.8rem;
        }

        .menu-submenu {
            &-list {
                padding: 0;
                margin-top: 0.4rem;
                margin-left: 1.7rem;
                padding-left: 1rem;
                border-left: 1px solid $slate-200;
            }


            &-item {
                padding: 0.6rem 0.8rem;
                border-radius: 0.8rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                transition: .5s all;

                &>.arrow-svg-menu {
                    width: 2rem;
                    height: 2rem;
                }

                &-info {
                    display: flex;
                    gap: 0.8rem;
                    align-items: center;

                    svg {
                        width: 2rem !important;
                        height: 2rem !important;
                        color: $slate-500;
                    }
                }
            }

            &-item-hover {
                cursor: pointer;

                &:hover {
                    background-color: $slate-200-45;
                }

                &:active {
                    background-color: $slate-200-70;
                }
            }


            &-disabled {
                @extend .menu-submenu-item;
                opacity: 0.3;
            }

            &-open {
                @extend .menu-submenu-item;
                background-color: $slate-200-45;

                .menu-submenu-item-info {
                    svg {
                        width: 2rem !important;
                        height: 2rem !important;
                        color: $slate-900 !important;
                    }

                    p {
                        color: $slate-900;
                    }
                }

                &:hover {
                    background-color: $slate-200-70 !important;
                }
            }


        }
    }

    &-option {
        height: 3.2rem;
        padding: 0.6rem 0.8rem;
        transition: .6s all;
        border-radius: 0.8rem;
        display: flex;
        align-items: center;
        gap: 0.8rem;
        cursor: pointer;

        a {
            text-decoration: none;
            display: flex;
            gap: 0.8rem;
            align-items: center;

            svg {
                width: 2rem;
                height: 2rem;
            }

        }

        a>svg {
            color: $slate-400;
            transition: .5s all;
        }

        &:hover {
            background-color: $slate-200-45;
        }
    }

    &-option-disabled {
        a>p {
            color: $slate-300 !important;
        }

        &:hover {
            background-color: $slate-100 !important;

            a>p {
                color: $slate-300 !important;
            }
        }
    }
}

.menu-submenu {
    .open {
        background-color: $slate-200-45;
    }

    .menu__content-option {
        &-title {
            display: flex;
            align-items: center;
            gap: 0.8rem;

            svg {
                width: 2rem;
                height: 2rem;
            }
        }
    }

    &-options {
        padding-left: 2rem !important;

        ul {
            li {
                padding: 0.6rem 0.8rem;
                height: 3.2rem;
                border-radius: 0.8rem;
                transition: .6s all;

                a {
                    text-decoration: none;
                }

                &:hover {
                    background-color: $slate-200-45;

                }
            }

            padding: 0;
            padding-left: 0.7rem;
            border-left: 1px solid $slate-200;
        }
    }
}