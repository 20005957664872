.item-categories {
  overflow: hidden;
  max-width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: $white;
  width: 60px;
  z-index: 2;
  transition: width .2s ease-in;

  .item-categories-container {
    padding: .5rem 0;
    overflow: hidden;
    flex: 2;

    @include scrollbar(5px, $gray2);
  }

  .clean-button {
    display: flex;
    height: 40px;
    justify-content: flex-start;
    align-items: center;
    transition: all .2s ease-in;

    p {
      display: none;
    }

    svg {
      display: block;
      margin-left: 10px;
    }
  }

  &:hover {
    width: 24rem;
    
    .item-categories-container {
      overflow-y: auto !important;
    }
    
    .clean-button { 
      justify-content: center;
      align-items: center;

      svg {
        display: none;
      }

      p {
        display: block;
      }
    }
  }

  .item-category {
    width: 24rem;
    padding: .25rem .5rem .25rem 0;
    cursor: pointer;

    &.selected {
      .indicator {
        border: 2px solid $primary;
      }
    }
    
    .category-container {
      height: 40px;
      width: 60px;
      margin-right: 10px;
      display: inherit;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;

      .indicator {
        width: 43px;
        height: 43px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
      }

      .background, .initial, img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        display: inherit;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        color: $white;
      }

      img {
        object-fit: cover;
        -o-object-fit: cover;
      }
    }

    .name {
      color: $title;
    }
  }


}