.add-payment-method {
  &__form-container {
    display: flex;
    column-gap: 1rem;
    justify-content: flex-start;
    padding: 2rem 3rem 0rem 1.5rem;
    width: 100%;

    & .s-textarea__textarea {
      min-height: 9rem !important;
      font-size: 1.4rem !important;
    }

    & .s-input__input {
      font-size: 1.4rem !important;
    }

    & .s-input__mark {
      color: #00b19d !important;
    }

    & .s-textarea__mark {
      color: #00b19d !important;
    }
  }
  &__form-container-mobile {
    margin: 0;
    padding: 0.5rem;
  }

  &__image {
    &_container {
      height: 190px;
      width: 190px;
      border: 2px $secondary dashed;
      aspect-ratio: 1;
      padding: 0.5rem;
      margin-top: 0.5rem;
      background-color: $slate-50;
    }
    &_container-mobile {
      width: 100%;
      border: 2px $secondary dashed;
      padding: 1rem 5rem;
      margin-bottom: 1rem;
      background-color: $slate-50;
    }
    &_QR {
      height: 104px;
      width: 158px;
      border: 2px dashed $slate-300;
      background-color: $slate-50;
      border-radius: 0.5rem;
    }
    &_QR > img {
      object-fit: contain;
    }
    &_QR-mobile {
      height: 104px;
      width: 136px;
      border: 2px dashed $slate-300;
      background-color: $slate-50;
      border-radius: 0.5rem;
    }
    &_QR-mobile > img {
      object-fit: contain;
    }
  }

  &__QR-text {
    padding-left: 0 !important;
  }

  &__QR-container {
    margin-top: 2rem;
    margin-left: 3rem;
    max-width: 60rem;
  }
  &__QR-container-mobile {
    display: flex;
    margin: 1rem 0 1rem 1rem;
    column-gap: 1rem;
    flex-direction: row-reverse;
  }

  .btn.disabled,
  .btn:disabled {
    opacity: 0.65;
    background-color: #a3adc2 !important;
  }
  .form-body,
  .company-form-body {
    overflow-y: auto !important;
    @include scrollbar(5px, $gray2);

    .form-group {
      label {
        &.required {
          &::after {
            display: none;
          }
        }
      }

      label {
        &.required > p {
          &::after {
            content: ' *';
            color: $primary;
          }
        }
      }
    }
  }
}
