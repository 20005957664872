.electronic-documents {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  &__info {
    width: 50%;

    @include media-breakpoint-down('md') {
      width: 100%;
    }
  }

  &__illustration {
    width: 40%;

    @include media-breakpoint-down('md') {
      display: none;
    }
  }
}
