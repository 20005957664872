.credit-limit-image-container {
  position: relative;
  width: 100%;
  height: 190px;
}

.credit-limit-image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.credit-limit-image-container img.active {
  opacity: 1;
}

.s-multi-date-picker-container {
  margin-top: 2px;
  margin-bottom: 2px;
}