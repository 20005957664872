&.modal__item {
  width: 50rem;
  min-width: 30rem;
  top: 0%;
  inset: unset;
  transform: translateY(0%);
  z-index: 101;

  @include media-breakpoint-up("md") {
    max-width: 68rem;
    width: 68rem;
  }

  .modal__item {
    &-body {
      padding: 2.5rem;
      // min-height: 44rem;
      // max-height: 44rem;

      @include media-breakpoint-up("sm") {
        // min-height: 34rem;
        // max-height: 36rem;
      }
      overflow-x: hidden;
      @include scrollbar(5px, $gray3);

      .btn-select-item-type {
        margin: 0;
        height: 3.8rem;
        min-width: 10rem;
        font-size: 1.3rem !important;
        border-radius: 5px;
        color: $gray3 !important;
        background: $white !important;
        border: 1px solid $gray3 !important;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.active {
          color: $primary !important;
          border: 1px solid $primary !important;
        }

        &:hover {
          &:not(:disabled) {
            border: 1px solid $primary !important;
          }
        }

        &:focus {
          box-shadow: none;
        }
      }
    }

    &-bottom {
      button {
        transition: all 0.2s ease;
      }
    }
  }

  .accordion-carret {
    transition: all 0.4s;
    &-up {
      transform: rotate(180deg);
    }
  }

  .accordion-body {
    transition: 0.4s ease-in-out;
    overflow: hidden;
  }
}
