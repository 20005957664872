.invoices {
  height: 100%;

  .invoice {
    color: $gray6;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    border-bottom: 1px solid $gray1;

    .price {
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
    }

    &:hover, &.selected {
      background: $gray1;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &-header {
    &__numeration {
      display: flex;
      flex-direction: column;
      @media (min-width: 972px) {
        flex-direction: row;
      }
    }
  }
}