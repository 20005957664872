.new-inventory-adjustment {
  font-family: "Inter";

  &-form-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    height: 522px;

    background: $white;
    border: 1px solid #e2e8f0;
    border-bottom: none;
    border-radius: 16px 16px 0px 0px;
    margin-top: 2.5rem;
  }

  &-image-container {
    width: 7rem;
    aspect-ratio: 1;
    border: 1px solid $gray2;
    border-radius: 10px;

    img {
      position: relative;
      object-fit: contain;
      -o-object-fit: contain;
      border-radius: 10px;
    }
  }

  &-header {
    display: flex;
    gap: 1.5rem;
  }

  &-total {
    height: 7.5rem;
    background: $white;
    border: 1px solid #e2e8f0;
    border-radius: 0px 0px 16px 16px;
    margin-bottom: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-inline: 60px;
  }

  &-bottom {
    font-family: "Inter";
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-required-message {
      font-size: 14px;
    }
    &-buttons {
      button:not(:last-child) {
        margin-right: 1.5rem;
      }
    }
  }
}


