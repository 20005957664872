&.modal__happy-week-experiment {
  .modal__happy-week-experiment-lg {
    max-width: 650px;

    p {
      margin: 0;
      position: absolute;
      cursor: pointer;
    }

    p.modal__happy-week-experiment-button-close {
      right: 12px;
      top: 14px;
      width: 36px;
      height: 36px;
    }

    p.modal__happy-week-experiment-button-info {
      bottom: 74px;
      right: 50%;
      transform: translateX(50%);
      width: 200px;
      height: 40px;
    }

    p.modal__happy-week-experiment-button-terms {
      bottom: 20px;
      right: 50%;
      width: 172px;
      height: 14px;
      transform: translateX(50%);
    }
  }

  .modal__happy-week-experiment-sm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;

    img {
      width: 50%;
      margin-bottom: 1rem;
      border-radius: 0.7rem;
    }
    
    p {
      width: 100%;
    }

    // p.modal__happy-week-experiment-button-close {
    //   background-color: red;
    //   right: 10px;
    //   top: 14px;
    //   width: 28px;
    //   height: 28px;
    // }

    // p.modal__happy-week-experiment-button-info {
    //   background-color: blue;

    //   bottom: 24px;
    //   right: 49%;
    //   transform: translateX(50%);
    //   width: 152px;
    //   height: 40px;
    // }

    // p.modal__happy-week-experiment-button-terms {
    //   background-color: green;
    //   opacity: 0.2;
    //   bottom: 74px;
    //   right: 50%;
    //   transform: translateX(50%);
    //   width: 200px;
    //   height: 40px;
    // }
  }
}