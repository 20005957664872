.p-relative {
  height: 100%;
  overflow-y: auto;
}

.plans {
  height: 100%;
  overflow-y: auto;
  @include scrollbar(5px, $gray2);

  &-container {
    background: $white;
    min-height: 85%;
    max-width: 100rem;
    width: 85%;
    margin: auto;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    &-info {
      z-index: 1;
      width: 50%;

      @include media-breakpoint-down('sm') {
        width: 100%;
      }
    }
  }

  .choose-plan {
    padding: 2rem 1rem;

    &__btn-group {
      position: relative;
      display: inline-flex;
      border-radius: 60px;
      background: #F0F2FA;

      button {
        border-radius: 60px;
        background: $white;
        border: none;
        background: transparent;
        color: $gray4;
        font-size: 1.4rem;
        min-width: 20rem;
        padding: .5rem 2rem;
        outline: none;

        &:focus {
          box-shadow: none;
        }

        &.selected {
          color: $primary;
          background: $white;
        }
      }
    }
  }

  .plan-container {
    padding: 1rem;
    width: 100%;

    .plan {
      background: $white;
      width: 30rem;
      border: 1px solid $gray1;
      min-height: 100px;
      border-radius: 8px;
      overflow: hidden;
    }

    .secondary-plan {
      justify-content: space-between;
      background: $white;
      width: 100%;
      max-width: 90rem;
      border: 1px solid $gray1;
      min-height: 100px;
      border-radius: 8px;
      overflow: hidden;
      padding: 2rem;

      & > div:nth-child(2) {
        width: 35rem;
      }

      @include media-breakpoint-down('xs') {
        justify-content: flex-start;
        flex-direction: column;
        width: 30rem;

        & > div:nth-child(2) {
          width: 25rem;
        }
      }
    }
  }
}
