.main-notification-top-container {
  background: rgb(205, 201, 255);

  &.has-notifications {
    height: auto;
    max-height: 132px;
    overflow: hidden;
  }

  &>* {
    display: none;
  }

  &>*:first-child {
    display: block;
    height: 100%;

    &>*:first-child {
      height: 100%;
    }
  }
}

.main-notifications {
  .with-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    button.close-button {
      top: initial !important;
    }
  }

  .notification {
    position: relative;
    padding: 1rem 3.2rem;
    color: $title;

    &-info {
      border-top: 2.5px solid $info-primary;
      background: $info-secondary;
    }

    &-warning {
      border-top: 2.5px solid $warning-primary;
      background: $warning-secondary;
    }

    &-danger {
      border-top: 2.5px solid $danger-primary;
      background: $danger-secondary;
    }

    button.close-button {
      position: absolute;
      right: .25rem;
      top: .3rem;
    }
  }
}

.located-notification-webcomponent {
  display: block;
  margin: 1rem 1.25rem 0 1.25rem;
  font-size: 1.3rem;

  @include media-breakpoint-down('sm') {
    display: none;
  }
}

.external-webcomponents-notification {
  position: relative;
  width: 100vw;
  z-index: 100;
  background: white
}