// Colors
@import 'colors';

// Variables
@import 'variables';

// Mixins
@import 'mixins';

// Initial
@import 'initial';

@import '~bootstrap/scss/bootstrap.scss';

// Components
@import "components/app";
@import "components/notifications";
@import "components/header";
@import "components/main";
@import "components/sidemodals";
@import "components/forms";
@import "components/settings";
@import "components/membership";
@import "components/modals";
@import "components/toast";
@import "components/hubspot";
@import "components/print";
@import "components/statistics";
@import "components/onboarding";
@import "components/inventory";
@import "components/items";
@import "components/action-buttons";
@import "components/details";
@import "components/inventory-adjustments";
@import "components/popover";
@import "components/network-status";
@import "components/electronic-payroll-status";
@import "components/react-select";
@import "components/buttons.scss";
@import "components/typography";
@import "components/tag";
@import "components/navigation-menu";
@import "components/date-picker";
@import "components/global-invoices/global-invoices";
@import "components/payment-methods";
@import "components/connection-modal";
@import "components/filter-tab";
@import "components/custom-breadcrumb";
@import "components/page-heading";
@import "components/loader";
@import "components/empty-state";
@import "components/electronic-documents";
@import "components/invoices/invoices";

@import "components/share_invoice";
@import "components/modals/body-invoice-saved";
@import "components/download_app";
@import "components/desktop";
@import "components/new_invoice_saved";
// External libraries
@import 'components/react-table';
@import 'components/react-table-gray';
@import 'components/react-table-light';
@import 'components/react-table-simplified';
@import 'components/react-datepicker';
@import 'components/global-discounts';

@include media-breakpoint-down('xs') {
  html,
  body {
    font-size: 9px;
  }
}
