.custom-date-picker-container {
    position: relative;
  
    & > div:nth-child(1) {
      position: absolute;
      bottom: 0;
      cursor: pointer;
      width: 100%;
      margin: 0;
  
      .ep-arrow {
        display: none;
      }
  
      & > div > div {
        width: 100%;
      }
  
      & > div > div > input {
        opacity: 0;
        cursor: pointer;
        width: 100%;
      }
    }
  }
  
  .rmdp-border-left {
    border: none !important;
  }
  
  .custom-date-picker-fast-options-container {
    background-color: $slate-50;
    padding: 1.2rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    width: 15.6rem;
    border-right: 1px solid $slate-200;
  
    &-item {
      width: 13.2rem;
      padding: 0.6rem 0.8rem;
      border-radius: 0.8rem;
      display: flex;
      cursor: pointer;
  
      @include media-breakpoint-down('sm') {
        width: 15.2rem;
      }
  
      &:hover {
        background-color: $slate-100;
      }
    }
  }
  
  .custom-input-multi-calendar {
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    border: 1px solid $slate-300;
    border-radius: 1rem;
    align-items: center;
    padding: 0.6rem 0.8rem;
  
    svg {
      color: $slate-500;
      height: 20px;
    }
  
    svg:first-child {
      margin-right: 0.4rem;
    }
  }
  
  .custom-select-filter-container {
    position: relative;
  
    svg {
      width: 16px;
      height: 16px;
    }
  }
  
  .custom-date-picker-container-input {
    .rmdp-top-class {
      padding-top: 0;
    }
  }
  
  .rmdp-container:has(.custom-date-picker-input) {
    width: 100%;
  }
  
  .custom-date-picker-input:placeholder-shown {
    color: #707070;
  }
  
  .custom-date-picker-input {
    border-radius: 5px;
    border: 1px solid #cccccc;
    width: 100%;
    height: 3.6rem;
    font-weight: 400;
    line-height: 1.5;
    color: #474747;
    font-size: 14px;
    padding: 0.375rem 0.75rem;
  }
  
  .custom-date-picker-input:disabled {
    background-color: #e9ecef;
    opacity: 1;
  }
  
  .rmdp-day.rmdp-selected span:not(.highlight) {
    box-shadow: none !important;
    background-color: $primary-600 !important;
  }
  
  .custom-date-picker {
    border: none !important;
    box-shadow: 0px 2px 6px -2px #0000000d;
    box-shadow: 0px 10px 15px -3px #0000001a;
  
    &.no-picker-header {
      .rmdp-top-class {
        .rmdp-header {
          display: none;
        }
      }
    }
  
    @include media-breakpoint-down('sm') {
      max-width: 100%;
  
      .rmdp-top-class {
        overflow-x: auto;
      }
  
      .rmdp-action-buttons {
        display: none !important;
      }
    }
  }
  
  .rmdp-border-top {
    border: none !important;
  }
  
  .rmdp-mobile {
    .rmdp-action-buttons {
      display: flex !important;
      margin-bottom: 1rem;
      margin-top: 1rem;
  
      button {
        margin: 0;
        color: $slate-700;
      }
  
      button:last-child {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        padding: 0.6rem 0.8rem;
        grid-gap: 0.4rem;
        gap: 0.4rem;
        min-height: 3.2rem;
        border: 1px solid transparent !important;
        border-radius: 0.8rem;
        background: #30aba9 !important;
        position: relative;
        font-size: 1.4rem;
        line-height: 2rem;
        font-style: normal;
        font-weight: 500;
        text-align: center;
        color: #fff;
        padding: 0.6rem 1.6rem;
      }
    }
  }
  
  .custom-date-picker-header {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.2rem;
    border: 1px solid $slate-200;
    border-width: 1px 0 0 0;
  }
  
  .custom-date-picker-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 1.2rem;
    height: 5rem;
    cursor: default;
  
    &-separator {
      width: 15.6rem;
      background-color: $slate-50;
      border-right: 1px solid $slate-200;
      height: 100%;
    }
  
    &-buttons {
      display: flex;
      gap: 16px;
    }
  }
  
  .rmdp-arrow-container:hover {
    background-color: transparent !important;
    box-shadow: none !important;
  }
  
  .rmdp-arrow {
    border: solid $slate-700 !important;
    border-width: 0 1px 1px 0 !important;
    height: 8px !important;
    width: 8px !important;
    cursor: pointer;
  }
  
  .rmdp-arrow-container.disabled {
    .rmdp-arrow {
      border: solid $slate-200 !important;
      border-width: 0 1px 1px 0 !important;
      cursor: default;
    }
  }
  
  .rmdp-header-values span:first-child,
  .rmdp-header-values span:last-child {
    position: relative;
    padding-right: 14px;
    font-weight: 600;
  
    &::before {
      content: '';
      position: absolute;
      border: solid $slate-700 !important;
      border-width: 0 0 2px 2px !important;
      height: 8px !important;
      width: 8px !important;
      right: -3px;
      top: 3px;
      transform: rotate(-45deg);
    }
  }
  
  .rmdp-header-values span:nth-child(2) {
    padding: 0 0 0 10px;
  }
  
  .rmdp-day-picker {
    gap: 10px;
  
    .rmdp-day {
      width: 40px !important;
      height: 40px !important;
    }
  
    .rmdp-day span {
      color: $slate-900;
      border-radius: 12px !important;
      width: 40px !important;
      height: 40px !important;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
  
      &:hover {
        color: $primary-600 !important;
        background-color: transparent !important;
        border: solid $primary-600 !important;
      }
    }
  
    .rmdp-week-day {
      color: $slate-500 !important;
      font-weight: 500;
      font-family: 'Inter Medium';
    }
  
    .rmdp-day.rmdp-range {
      background-color: $primary-50 !important;
      box-shadow: none !important;
    }
  
    .rmdp-deactive span,
    .rmdp-disabled span {
      color: $slate-300 !important;
      cursor: default !important;
  
      &:hover {
        color: $slate-300 !important;
        background-color: transparent !important;
        border: none !important;
      }
    }
  
    .rmdp-day.rmdp-range.start,
    .rmdp-day.rmdp-range.end {
      background-color: $primary-50 !important;
  
      position: relative;
  
      &::before {
        content: '';
        position: absolute;
        background-color: $primary-600;
        width: 100%;
        height: 100%;
        border-radius: 12px !important;
        top: 0;
        left: 0;
      }
  
      span {
        color: $white;
  
        &:hover {
          color: $white !important;
        }
      }
    }
  
    .rmdp-day-picker > div {
      width: 280px;
  
      .rmdp-week {
        height: 40px;
      }
  
      .rmdp-week:first-child {
        height: 32px;
      }
    }
  }
  
  .rmdp-year-picker,
  .rmdp-month-picker {
    min-width: 280px;
  
    .rmdp-day span {
      color: $slate-900;
      border-radius: 12px !important;
      height: 100% !important;
      position: relative;
      top: inherit;
      bottom: inherit;
      left: inherit;
      right: inherit;
  
      &:hover {
        color: $primary-600 !important;
        background-color: transparent !important;
        border: solid $primary-600 !important;
      }
    }
  
    .rmdp-day.rmdp-selected {
      background-color: $primary-600;
      border-radius: 12px !important;
  
      span {
        color: $white;
        background-color: transparent !important;
        box-shadow: none !important;
        height: 100% !important;
  
        &:hover {
          color: $white !important;
        }
      }
    }
  
    .rmdp-deactive span,
    .rmdp-disabled span {
      color: $slate-300 !important;
      cursor: default !important;
  
      &:hover {
        color: $slate-300 !important;
        background-color: transparent !important;
        border: none !important;
      }
    }
  }
  