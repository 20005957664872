.payments {
  height: 100%;

  .payment {
    color: $subtitle;
    border-bottom: 1px solid $gray1;

    .title, .price {
      color: $title;
      font-weight: 500;
    }

    &:hover, &.selected {
      background: $gray1;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
