&.modal__products-onboarding {
  min-height: 42rem;
  border-radius: 10px;
  position: relative;

  @include media-breakpoint-up('md') {
    max-width: 75rem;
    width: 75rem;
  }
  
  @include media-breakpoint-down('sm') {
    width: 50rem;
  }

  .modal__header {
    border-bottom: none !important;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  .body-content {
    max-width: 50rem;
  }

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 133%;
    align-items: center;
  }

  p {
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 145.69%;
  }

  a {
    font-size: 1.4rem;
    color: #fff !important;
  }
}