.contact-form {
  display: flex;
  flex-direction: column;

  &-body {
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
    @include scrollbar(0, $gray3);
  }

  &-footer {
    width: 100%;
    background-color: white;
    padding-top: 10px;
    position: relative;

    &-divider {
        top: 0;
        left: -40px;
        position: absolute;
       width: 1000px;
        height: 1px;
        background-color: rgba(226, 232, 240, 0.445)
    }
  }
}

.duplicated-contact-modal {
  background-color: white;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}