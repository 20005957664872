&.modal__activate-caea {
    position: absolute;
    background-image: url('../../../components/images/fe arg configure caea.png');
    background-size: 855px 450px;
    background-repeat: no-repeat;
    max-width: 855px;
    min-width: 855px;
    height: 450px;
}

&.modal__activate-fe {
    position: absolute;
    background-image: url('../../../components/images/configure fe arg.png');
    background-size: 855px 450px;
    background-repeat: no-repeat;
    max-width: 855px;
    min-width: 855px;
    height: 450px;
}