.new-item {
  overflow-y: auto;
  max-height: 100%;
  @include scrollbar(0px, $gray3);

  position: relative;
  transition: height .5s;

  &-primary-view {
    flex: 1;
    height: fit-content;

    .btn-select-item-type {
      margin: 0;
      height: 3.8rem;
      min-width: 7rem;
      font-size: 1.3rem !important;
      border-radius: 5px;
      color: $gray3 !important;
      background: $white !important;
      border: 1px solid $gray3 !important;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.active {
        color: $primary !important;
        border: 1px solid $primary !important;
      }

      &:hover {
        &:not(:disabled) {
          border: 1px solid $primary !important;
        }
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
  
  &-secondary-view {
    width: 35rem;
    margin-left: 8px;
    position: sticky;
    top: 0;
    height: fit-content;

    .image-container {
      width: 35rem;
      height: 35rem;
      background: $gray1;
      position: relative;

      .image-help-text {
        display: block;
        padding: 48% 0;
        opacity: 0;
        cursor: pointer;
        background: rgba(#000000, 0.72);
        color: #fff;
        object-fit: contain;
        text-align: center;
        align-items: center;
      }

      .image-help-text:hover {
        opacity: 1;
      }
      
    }

    .form-group {
      margin-bottom: 0;
    }
  }

  img {
    object-fit: contain;
  }

  textarea {
    height: 5rem !important;
  }

  .react-datepicker-wrapper {
    position: relative;

    input {
      height: 3.6rem;
      max-width: 100%;
    }
  }

  .combo-item-container {
    padding: .5rem;
    border-radius: 8px;
    margin: .5rem 0;
    .image-container {
      width: 8rem;
      border-radius: 5px;
      border: 1px solid $gray2;
      position: relative;
      overflow: hidden;

      img {
        position: absolute;
        object-fit: contain;
        z-index: 1;
      }
    }
  }

  .form-field-array-wrapper {
    max-height: unset;
  }

  .btn-variant {
    margin: 0;
    height: 3.8rem;
    min-width: 10rem;
    font-weight: 500;
    font-size: 1.3rem !important;
    border-radius: 5px;
    color: $gray4 !important;
    background: $gray1 !important;

    &.active {
      color: $white !important;
      background: $primary !important;
    }

    &::first-letter {
      text-transform: uppercase !important;
    }
  }
}
.select-kit-item-modal {
  min-width: 50rem;
  @include media-breakpoint-down("xs") {
    min-width: 40rem;
  }
}