.company-logo-container,
.new-company-logo-container {
  display: inline-block;
  border: 2px dashed $gray3;
  box-sizing: border-box;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  position: relative;
  width: calc(178px * 1.5);
  height: calc(53px * 1.5);
  margin: 5px 12px 16px 0;

  .profile-logo {
    width: 100%;
    height: 100%;
  }

  .profile-logo-empty {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    &>* {
      margin: 0;
      color: $gray3;
    }

    h3 {
      font-weight: 500;
      font-size: 3rem;
    }

    p {
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 18px;
    }
  }

  .logo-selector {
    position: absolute;
    bottom: -12.5px;
    right: -12.5px;
    width: 36px;
    height: 36px;
    cursor: pointer;

    .logo-button {
      border-radius: 50%;
      box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
      position: relative;
      width: 100%;
      height: 100%;
      padding: 6px;
      line-height: normal;
      margin: 0;
      cursor: pointer;
      background: $white;
      border: 1px solid #eee;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        svg {
          color: $primary;
        }
      }

      svg {
        width: 24px;
        height: 24px;
        color: $blue-dark;
      }

      input {
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
        -moz-opacity: 0;
        -khtml-opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        padding: 0;

        &,
        &::-webkit-file-upload-button {
          cursor: pointer;
        }
      }
    }
  }
}

.logo-editor-modal {
  padding: 0;

  .logo-editor-component {
    width: Min(90vw, 600px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    >* {
      width: 100%;
    }

    .reactEasyCrop_Container {
      border-radius: 2rem 2rem 0 0;
    }

    .logo-editor {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      min-height: Min(90vh, 400px);
      background: transparent;

      .reactEasyCrop_CropAreaGrid {
        border-color: #39f;

        &::before,
        &::after {
          border-color: #eee;
          border-style: dashed;
        }
      }

      .image-actions {
        position: absolute;
        bottom: 10px;
        right: 10px;
        display: flex;

        .image-action {
          margin: 0;
          border-radius: 50%;
          box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06);
          width: 40px;
          height: 40px;
          background: $white;
          border: 1px solid #eee;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;

          &:not(:last-child) {
            margin-right: 10px;
          }

          color: $gray5;
        }
      }
    }

    .bottom {
      display: flex;
      box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.3);
      padding: 15px;
      justify-content: flex-end;
      align-items: center;
      border-radius: 0 0 2rem 2rem;

      &__buttons {
        display: flex;
        justify-content: space-between;

        button {
          margin: 0;
          height: 3.8rem;
          min-width: 10rem;
          letter-spacing: 0;
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 0.75em;
          line-height: 22px;

          &:not(:last-child) {
            margin-right: 5px;
          }
        }

        .cancel-button {
          color: $blue-dark;
          font-weight: 700;
          border-color: $gray3;

          &:hover {
            border-color: $primary;
          }
        }
      }
    }
  }
}

.new-company-logo-container {
  border: 2px solid #00B19D;
  border-radius: 7px;
  width: 250px;
  height: 128.65px;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  margin: 5px 48px 16px 0;

  .profile-logo {
    width: 100%;
    height: auto;
  }

  .logo-selector {
    width: 48px;
    height: 48px;

    .logo-button {
      box-shadow: none;
      border: 1px solid #E2E8F0;

      svg {
        width: 18px;
        height: 18px;

        color: black !important;
      }
    }

    .logo-options-dropdown {
      font-family: 'Inter';
      font-size: 14px;
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;
      width: 173px;
      height: 80px;
      top: 10px;
      left: 59px;
      box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.15);
      border-radius: 4px;

      p {
        display: block;
        width: 100%;
        margin: 0;
        padding: 10px 15px;
      }

      p:hover {
        background-color: #f8fafc;
      }
    }
  }
}