&.modal__invoice {
  width: auto;

  @include media-breakpoint-up('md') {
    min-width: 70rem;
  }

  @include media-breakpoint-down('md') {
    width: 50rem;
  }

  &-saved-offline {
    max-width: 48rem;
    padding: 1.6rem;
  }

  .modal__invoice {
    &-body {
      min-height: 30rem;
    }

    &-btn-change {
      margin: 0;
      height: 3.8rem;
      min-width: 10rem;
      font-weight: 500;
      font-size: 1.25rem !important;
      right: 0;
      color: $primary;
      text-transform: uppercase;
    }

    &-divider {
      &-text {
        margin: 0 auto;
        width: fit-content;
        z-index: 1;
        background: $white;
        padding: 0 0.5rem;
      }

      &-line {
        width: 100%;
        display: block;
        position: absolute;
        top: 50%;
      }
    }

    &-basic-data {
      width: 100%;

      @include media-breakpoint-up('sm') {
        width: 55%;
      }

      @include media-breakpoint-down('xs') {
      }
    }

    &-advanced-data {
      width: 100%;

      .anotation_label {
        display: flex;

        div {
          font-size: 1.2rem;
          color: #707070;
          width: 100%;
        }
      }

      @include media-breakpoint-up('sm') {
        width: 45%;
      }
    }

    &-segmented-control {
      width: 100%;
      display: flex;
      background-color: rgba(226, 232, 240, 0.45);
      border-radius: 1rem;
      height: 4rem;

      &-active {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3.2rem;
        border: none !important;
        border-radius: 0.8rem;
        background: white;
        box-shadow:
          0px 1px 3px rgba(0, 0, 0, 0.1),
          0px 1px 2px rgba(0, 0, 0, 0.06);
        cursor: pointer;
      }

      &-inactive {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3.2rem;
        border: none !important;
        border-radius: 0.8rem;
        cursor: pointer;
      }
    }

    &-total {
      &-title > p {
        color: $slate-500;
        margin-bottom: 0;
      }
      &-amount {
        color: $slate-900;
      }
    }

    &-input-label {
      margin-bottom: 1px;
      color: $slate-700;

      label {
        &.required > div > p {
          &::after {
            content: ' a';
          }
        }
      }
    }

    &-anotation {
      &-container {
        width: 95%;
      }
      &-label {
        color: #30aba9;
      }
      &-field input {
        border-radius: 12px;
        border: 1px solid #30aba9 !important;
      }
    }

    &-success {
      &-title {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        color: $slate-700;
      }

      &-message {
        color: $slate-700;
      }

      &-total {
        padding: 10px;
        border-radius: 8px;
        background: var(--surface-surface-subtle-slate-50, #f8fafc);
        color: $slate-700;
      }
    }

    &-combined {
      &-table {
        width: 100%;
        & tr {
          color: $slate-700;
          &:first-child th:first-child {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          }
          &:last-child th:last-child {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          }
        }
        & th {
          background-color: $slate-50;
          width: 33%;
          padding: 8px 16px;

          & p::after {
            content: '*';
            color: #30aba9;
          }
        }
        & td {
          & > .col-sm-12 {
            padding: 0px;
          }
          & > div > .col-sm-12,
          .col-sm-10 {
            padding: 0px;
          }
        }
      }
      &-btn {
        &-add > button > p {
          margin-bottom: 0 !important;
        }
        &-remove {
          margin-bottom: 1rem;
        }
      }
    }

    &-custom-payment {
      &-instruction {
        background-color: $slate-50;
        padding: 1.5rem !important;
        border-radius: 1rem;
      }
    }
  }

  .other-payment-methods {
    right: 0;
  }

  .btn-invoice-method {
    height: 100px;
    width: 150px;
    margin-bottom: 1rem;

    &-border {
      width: 115px;
      height: 100px;
      border-radius: 12px;
      border: 1px solid $slate-300;
      background-color: white;
      padding-top: 1.5rem;

      &:hover {
        border: 2px solid #30aba9;
        p {
          color: $slate-700;
        }
      }

      @media screen and (max-width: 768px) {
        width: 100%;
        height: 56px;
        padding: 12px 16px;
      }
    }
    &-text {
      color: $slate-500;
      p {
        line-height: 16px;
      }

      @media screen and (max-width: 768px) {
        color: $slate-700;
      }
    }
    &-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 48px;
      & > img {
        height: 48px;
        object-fit: contain;
      }
    }
  }
}

&.modal__invoice-5uvt-new-client {
  max-width: 70rem;
}

.rmdp-top-class {
  padding-top: 0;
}

.rmdp-header-values span:first-child,
.rmdp-header-values span:last-child {
  &::before {
    display: none;
  }
}

.rmdp-header-values span {
  padding: 0 !important;
}

.rmdp-container {
  width: 100%;
}

.rmdp-wrapper {
  border-radius: 16px;
}

.rmdp-calendar {
  background-color: $slate-50;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.rmdp-day-picker .rmdp-day {
  width: 32px !important;
  height: 35px !important;
}

.rmdp-input {
  width: 100%;
  height: 48px;
  font-size: 16px;
  color: $slate-700;
  border-radius: 12px;
  border: 1px solid $slate-300;
  padding: 2px 8px;
}

.rmdp-day-picker .rmdp-day span {
  width: 32px !important;
  height: 35px !important;
  border-radius: 4px !important;
}

.rmdp-arrow-container {
  border-radius: 9px;
  background-color: #cff2f1;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  color: #1a7e7f;
  background-color: #cff2f1;
}

.rmdp-arrow {
  border: solid #1a7e7f !important;
  border-width: 0 1px 1px 0 !important;
}

.rmdp-arrow-container.disabled .rmdp-arrow {
  border: solid $gray2 !important;
  border-width: 0 1px 1px 0 !important;
}

.Select__control {
  border-radius: 12px;
  border: 1px solid $slate-300;
}

.invoice-plugin {
  &-calendar {
    max-width: 192px;
    padding: 8px;
    &-option {
      padding: 10px 8px;
      gap: 8px;
      cursor: pointer;
      &-check {
        width: 16px;
        height: 16px;
      }
    }
    &-option > p {
      color: $slate-900;
    }
  }
}

.swiper {
  &-slide {
    display: flex;
    justify-content: center;
  }
}

.carousel-container {
  position: relative;
  &-swiper {
    max-width: 58em;
  }
  &-button-next {
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #e2e8f073;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-button-prev {
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e2e8f073;
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }
}

.mobile-payment-methods-container {
  max-height: 20em;
  overflow-y: auto;
}

.payment-method-container {
  border-radius: 16px;
  background-color: #f8fafc;
  padding: 12px;
  width: 90%;
}

.back-btn {
  color: #30aba9;
}
.modal__invoice-saved-offline {
  &-body {
    padding: 1.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1.6rem;
  }
}

@keyframes pointsAnimation {
  0% {
    background-color: $slate-700;
  }
  50%,
  100% {
    background-color: $slate-300;
  }
}
