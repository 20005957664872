&.modal__plan-limit-reached {
    background-image: url('/assets/images/modal_background.png');
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;

    @include media-breakpoint-up('md') {
      max-width: 100rem;
      width: 100rem;
    }
    
    @include media-breakpoint-down('sm') {
      width: 50rem;
    }

    .modal__header {
        border-bottom: 0 !important;
        padding-bottom: 0 !important;
    }

    .modal_content {
        width: 100%;
        padding: 0 !important;
        margin-top: 4rem;

        .left-content {
            width: 55%;
            padding-right: 5%;
            padding-left: 5rem;
            margin-top: 3rem;
            margin-bottom: 3rem;

            .success-icon {
              margin-right: 2rem;
            }

            p {
              margin-bottom: 2rem !important;
            }

            .left-content-button {
              display: flex;
              margin-top: 5rem;

              button {
                width: 80%;
              }
            }
            
            
            .left-content-bottom {
              display: flex;
              align-items: center;
              margin-top: 2rem;

              p {
                margin-bottom: 0 !important;
              }

              a {
                background: $gray1;
                padding: 0.5rem;
                border-radius: 0.5rem;
                color: $gray6;
                margin-left: 1rem;
                padding-left: 1rem;
                padding-right: 1rem;
                border-radius: 1rem;
                border: 0.01rem solid $gray4;
              }
            }
        }

        .right-content {
            width: 45%;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;

            .pos-img {
              width: 100%;
            }
        }
    }

  }

  @include media-breakpoint-down('md') {
    .modal_content {
      .left-content {
        .left-content-bottom {
          flex-wrap: wrap;
          justify-content: center;
        }
      }
    }
  }

  @include media-breakpoint-down('sm') {
    .modal_content {
      margin-top: 2rem !important;

      .left-content {
        width: 100% !important;
        padding-right: 5rem !important;
        justify-content: center;

        .left-content-button {
          justify-content: center;
          margin-top: 2rem !important;
        }

        .left-content-list {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }

      .right-content {
        display: none o !important;
        width: 0% !important;
      }
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateX(-100px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateX(-100px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateX(-100px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateX(-100px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateX(-100px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }