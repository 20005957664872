.page__download-app {
  display: grid;
  grid-template-columns: 60% 1fr;
  grid-template-rows: 1fr;
  width: 100vw;
  height: 100vh;

  &__images-content {
    background-color: #EDF8FF;
    display: flex;
    align-items: center;
  }

  &__text-content {
    background-color: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 72px;

    &__container {
      display: flex;
      flex-direction: column;
      gap: 28px;
      height: 60%;
      justify-content: center;
    }

    &__actions {
      display: flex;
      gap: 16px;
      flex-direction: column;
    }
  }
}