.invoices {
  &-container {
    display: flex;
    flex-direction: column;
    max-width: 90vw;
    margin: auto;
    margin-top: 3rem;
    overflow-y: auto;
    height: 85vh;

    &::-webkit-scrollbar {
      display: none;
    }

    scrollbar-width: none;

    -ms-overflow-style: none;

    & .custom-date-picker-input {
      height: 3.2rem !important;
      border-radius: 10px !important;
    }
  }

  &-table {
    & > .s-table {
      background: #f1f5f9;
      border-top: none;
      border-left: none;
      border-right: none;

      & > .s-table-toolbar {
        border: none;
      }

      & > .s-table-pagination {
        background: white;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
      }
      & > .s-table__wrapper {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        border-right: 1px solid rgb(226, 232, 240);
        border-left: 1px solid rgb(226, 232, 240);
        border-top: 1px solid rgb(226, 232, 240);
        & > .s-table__row tbody {
          height: 64vh !important;
          overflow-y: auto;
          background-color: white;

          &::-webkit-scrollbar {
            width: 11px;
            height: 3px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #cbd5e1;
            border: 1px solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
            border-radius: 15px;
          }
        }
      }
    }

    & .s-table-partible-content-body {
      height: 64vh !important;
      overflow-y: auto;
      padding-bottom: 0 !important;
      background-color: white;

      &::-webkit-scrollbar {
        width: 11px;
        height: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #cbd5e1;
        border: 1px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 15px;
      }
    }

    & .s-table-partible-content .selected-row {
      position: relative;
    }

    & .s-table-container-empty {
      background-color: white;
    }
    .s-table-header-actions,
    .s-table-bulk-actions,
    .s-table-toolbar {
      height: 30px;
    }
  }
}

.invoice {
  &-detail {
    &-editable {
      &-footer {
        position: sticky;
        bottom: 0;
        margin-top: 2rem;
        margin-left: -15px;
        margin-right: -15px;
        height: 50px;
        border-top: 1px solid #94a3b833;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        padding: 0 16px;
      }
    }
  }
  &-edit {
    &-table {
      &-container {
        border: 1px solid #e1e8ef;
        border-radius: 16px;
        overflow: hidden;
      }
      width: 100%;

      .form-control {
        border: 1px solid #94a3b866 !important;
        border-radius: 10px;
      }
      .form-group {
        margin-bottom: 0;
      }
      & thead {
        border-width: 0 0 1px 0;
        border-color: #e1e8ef;
        border-style: solid;

        & tr {
          background-color: #f8fafc;
          height: 40px;

          & th {
            padding: 0 16px;

            &.right {
              text-align: right;
            }

            &.product {
              width: 33%;
            }
            &.quantity {
              width: 15%;
            }
            &.discount {
              width: 15%;
            }
            &.tax {
              width: 20%;
            }

            &.payment-number {
              width: 20%;
            }
            &.payment-date {
              width: 25%;
            }
            &.payment-method {
              width: 30%;
            }
          }

          & th:last-child {
            padding: 0px;
          }
        }
      }

      & tbody {
        & tr {
          border-bottom: 1px solid #e1e8ef;
          & td {
            padding: 1rem 12px 10px 12px;
            &.right {
              text-align: right;
            }
            &.product {
              width: 33%;
            }
            &.quantity {
              width: 15%;
            }
            &.discount {
              width: 15%;
            }
            &.tax {
              width: 20%;
            }

            &.payment-number {
              width: 20%;
            }
            &.payment-date {
              width: 25%;
            }
            &.payment-method {
              width: 30%;
            }
          }

          & td:last-child {
            padding: 0px;
          }
        }
      }
    }
  }
}

.payment-summary-container {
  display: grid;
  grid-template-columns: 2fr 2fr;
  border: 1px solid #e2e8f0;
  border-radius: 16px;
  overflow: hidden;

  & .content-summary {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 15rem;
    padding: 12px 16px;

    &:last-child {
      border-top: 1px solid #e2e8f0;
    }
  }

  & .header-summary {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #f8fafc;
    min-width: 15rem;
    padding: 12px 16px;
    border-right: 1px solid #e2e8f0;

    &:first-child {
      border-bottom: 1px solid #e2e8f0;
    }
  }
}
