.global-discounts {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;

  & > .s-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  &__item-price {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;

    &>div {
      min-width: 80px;
      width: fit-content;
    }
  }

  &__close-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &__table {
    flex: 1;
    overflow-y: auto;

    .s-table {
      height: fit-content;
      border: none;
      background-color: transparent;
    }
  }
}
