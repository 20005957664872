.details {
  font-family: "Inter";
  background: $white;
  border-left: solid 1px #e1e8ef;
  width: 60%;
  overflow: scroll;

  @include scrollbar(5px, $primary);

  &-full-screen {
    @extend .details;

    margin: 42px;
    width: 1027px;

    border: 1px solid #e2e8f0;
    border-radius: 8px;
  }

  @include media-breakpoint-down("md") {
    width: 100%;
    margin: 24px;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
  }

  &__options {
    height: 7%;
    border-bottom: solid 2px #e1e8ef;
    padding-inline: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &-close-details {
      @include media-breakpoint-down("md") {
        display: none !important;
      }
    }

    &-back {
      @include media-breakpoint-up("lg") {
        display: none !important;
      }
    }
  }

  &__body {
    height: 78%;
    padding: 3rem;

    &__info {
      display: flex;
      justify-content: space-between;
      gap: 5rem;

      div {
        flex-grow: 1;

        border-bottom: solid 2px #e1e8ef;
      }
    }

    &__table-container {
      @include scrollbar(5px, $primary);

      overflow-x: scroll;
    }

    &__table {
      width: 100%;
      table-layout: auto;

      thead {
        height: 4rem;
        font-size: 12px;
      }

      th {
        text-align: end;
        padding: 1rem;
        background: #f8fafc;
      }

      th:first-child {
        width: 30%;
        border-radius: 10px 0px 0px 10px;
      }

      th:last-child {
        border-radius: 0px 10px 10px 0px;
      }

      tbody {
        tr {
          &:not(:last-child) {
            border-bottom: solid 1px #e1e8ef;
          }
        }

        td {
          height: 8rem;
          padding: 1rem;
          text-align: end;
        }
      }
    }
  }

  &__footer {
    padding: 3rem;

    &__limitator {
      border-top: solid 2px #e1e8ef;
    }
  }
}

.detail-table-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 24px 48px 0px 48px;
  width: 40%;

  @include media-breakpoint-down("md") {
    display: none;
    padding: 0px;
  }
}

.hide-detail-table-container {
  display: none;
  padding: 0px;
}
