&.modal__stations {
  top: 0%;
  inset: unset !important;
  padding: 0 !important;
  transform: translateY(0%);
  @include media-breakpoint-up('md') {
    max-width: 75rem;
    width: 75rem;
  }
  
  @include media-breakpoint-down('sm') {
    width: 50rem;
  }

}