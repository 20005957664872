.search {
  padding: 1.5rem;

  .btn-add-item {
    font-size: 1.4rem;
    color: $primary;
    font-weight: 500;
    border: 1px solid $primary;
    min-width: 17rem;
    padding-left: 1rem;
    padding-right: 1rem;
    min-height: 3.8rem;

    &:disabled {
      color: $subtitle;
      border-color: $subtitle;
    }

    @include media-breakpoint-down('md') {
      min-width: 15rem;
      padding-left: .25rem;
      padding-right: .25rem;
    }

    @include media-breakpoint-down('sm') {
      min-width: 4rem;
      padding-left: .25rem;
      padding-right: .25rem;
    }
  }

  .search-bar {
    height: 3.8rem;
    width: 100%;

    & > button {
      height: 100%;
      padding: 0 1.5rem;
      border-radius: 0;

      @include media-breakpoint-down('md') {
        padding: 0 .5rem;
      }

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }

    .search-bar-input {
      width: 100%;
      height: 100%;

      border: 1px solid $primary;
      border-left: none;
      color: $title;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      padding: 0 4rem 0 1rem;
      outline: none;

      @include media-breakpoint-down('md') {
        padding: 0 4rem 0 .5rem;
      }

      &::-webkit-input-placeholder { /* WebKit browsers */
        color: $subtitle;
      }
      &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: $subtitle;
      }
      &::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: $subtitle;
      }
      &:-ms-input-placeholder { /* Internet Explorer 10+ */
        color: $subtitle;
      }
      &::placeholder { /* Recent browsers */
        color: $subtitle;
      }
    }

    .search-bar-container {
      position: relative;

      button {
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
  }
}