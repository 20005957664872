.shifts {
  &__list {
    flex: 1 0 20%;
    max-width: 35rem;
    background: $white;
    transition: all .2s ease;

    @include media-breakpoint-down('xs') {
      position: absolute;
      flex: 1 1 100%;
      max-width: 100vw;
      width: 100%;
      height: 100%;
      transform: translateX(-100vw);
      z-index: 1;

      &.open {
        transform: translateX(0);
      }
    }

    &-header {
      box-shadow: 0px 0.5px 4px $gray3;
    }

    &-body {
      flex: 1 1 100%;

      &-shifts {
        max-height: 100%;
        overflow: hidden;
        overflow-y: auto;
        overflow-x: auto;

        @include scrollbar(7px, $gray3);
        
        .shift:hover, .shift.active {
          background: $gray1;
        }
      }
    }

    .pagination-empty {
      .card {
        border: none;
      }
    }

    .pagination-bottom {
      input.pagination-per-page {
        border: 1px solid $white;
        border-radius: 5px;
        background: $primary;
        color: $white;
        margin: 0;
        width: 4.2rem;
        outline: none;
        padding: 0 5px;
        height: 3rem;
        text-align: end;

        &:focus, &:active {
          border: 1px solid $white;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none !important;
          margin: 0 !important;
        }
        -moz-appearance: textfield !important;
      }
    }
  }

  &__detail {
    flex: 2;
    background: $empty;
    padding: 4rem 4.5rem;
    transition: all .2s ease;
    overflow: hidden;
    overflow-y: auto;
    overflow-x: auto;
    
    @include scrollbar(7px, $gray3);

    @include media-breakpoint-down('xs') {
      flex: 1 1 100%;
      max-width: 100vw;
      padding: 2rem;
    }

    &-body-card {
      width: 160px;
      font-size: 16px;
    }

    &-body {
      font-family: 'Inter';
      &-title {
        margin-bottom: 2px;
        &-bold {
          font-weight: 600;
        }
      }
      &-date {
        font-size: 14px;
        color: #64748B;
      }
      &-total-cash {
        font-size: 20px;
      }
      .h4 {
        font-size: 14px;
      }
    }

    &-header {
      font-family: 'Inter';
      &-title {
        font-family: 'Inter Semibold';
        font-size: 20px;
        color: rgba(51, 65, 85, 1);
        margin-bottom: 0;
      }
      &-description {
        font-size: 16px;
      }
      &-actions {
        button {
          margin-bottom: 1rem;
          margin-right: 1rem;
        }
        @include media-breakpoint-down('xs') {
          button {
            min-width: unset !important;
          }
        }
      }
    }

  }
}