&.modal__company-data-update {
  max-width: 64rem;
  border-radius: 2rem;

  display: flex;
  justify-content: flex-start;

  .modal__header {
    display: none !important;
  }

  .data-compare {
    display: flex;
    height: 17.6rem;
    align-items: center;
    width: 100%;

    padding-left: 3rem;

    // styles for children divs
    div {
      width: 100%;
    }

    .compare-child {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 50%;
      height: 100%;
      gap: 0.5rem;
    }
  }

  // class that represents a separator
  .separator {
    width: 0.1rem !important;
    height: 100%;
    background-color: $gray2;
  }

  .modal__bottom {
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    gap: 8rem;
  }

  // Media query for mobile devices
  @media (max-width: 768px) {
    .data-compare {
      height: auto;
    }
  }
}
