.error-detail-modal {
    @extend .confirm-action-modal;

    &-body {
        &-table {
            margin-top: 1rem;
            border: 1px solid $slate-200;
            border-radius: 1.6rem;
            overflow: hidden;
            overflow-y: auto;
            max-height: 300px;

            @include scrollbar(5px, $gray3);

            table {
                width: 100%;
                table-layout: fixed;

                .one {
                    width: 30%;
                }

                .two {
                    width: 70%;
                }

                tr {
                    height: 48px;
                    border-bottom: 1px solid $slate-200;

                    th,
                    td {
                        padding: 0.2rem 1.6rem;
                    }

                    td:nth-child(1) p {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }

                tr:nth-child(2) {
                    height: 40px;
                    background-color: $slate-50;

                    th:first-child {
                        height: 40px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .separator {
                            width: 1px;
                            height: 50%;
                            background-color: $slate-200;
                        }
                    }
                }

                tr:last-child {
                    border-bottom: none
                }
            }

        }
    }

    &-actions {
        @extend .confirm-action-modal-actions;
    }
}