.side-modal {
  &__wrapper {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: relative;

    &.open {
      background: rgba(51, 51, 51, .7);
      z-index: 100;
    }

    &.closed {
      z-index: -1;
      background: transparent;
      transition: all 0s .2s linear;
    }
  }

  &__tip-body {

    .form-group {
      margin-bottom: 0;
    }

    .input-group-icon {
      position: relative;

      span {
        position: absolute;
        top: 46px;
        right: 10px;
        color: #64748B;
        font-size: 16px;
      }

      .value-icon {
        top: 31px;
        left: 10px;
        width: 17px;
      }

      @include media-breakpoint-down('xs') {
        span {
          top: 40px;
        }

        .value-icon {
          top: 26px;
        }
      }
    }

    &-input-container {
      display: flex;
      flex-direction: column;

      p {
        color: #64748B;
        font-size: 12px;
      }

    }

    input[name='percentage'],
    input[name='value'] {
      width: 100%;
      border: 1px solid #CBD5E1 !important;
    }

    input[name='percentage']:disabled,
    input[name='value']:disabled {
      background-color: #e2e8f05a;
      color: #64748b60;
      border: 1px solid rgb(193, 202, 213);
    }

    input[name='value'] {
      padding-left: 40px;
    }

    .input-number-wrapper {
      background: transparent !important;
    }
  }

  .inactive {
    .input-group-icon {
      color: #64748b60;

      span {
        color: #64748b60;
      }

    }

    p {
      color: #64748b60;
    }
  }

  .btn-group {
    margin-top: 25px;
    padding: 5px;
    background-color: #E2E8F073;
    border-radius: 10px;

    button {
      border-radius: 8px;
      background-color: transparent;
      border: none;
      outline: none;
      width: 50%;
      padding: 4px 0;

      &.active {
        box-shadow: 0px 1px 2px 0px #0000000F;
        box-shadow: 0px 1px 3px 0px #0000001A;
        background-color: #FFF;
      }
    }
  }

  &__children-wrapper,
  &__children-wrapper-new {
    background: $white;
    position: absolute;
    top: 0;
    bottom: 0;
    transition: transform .2s ease-in-out;

    &.right {
      right: 0;

      &.closed {
        transform: translateX(100vh);
      }
    }

    &.left {
      left: 0;

      &.closed {
        transform: translateX(-100vh);
      }
    }

    @include media-breakpoint-up('md') {
      width: 550px;
    }

    @include media-breakpoint-down('md') {
      width: 60vw;
    }

    @include media-breakpoint-down('sm') {
      width: 70vw;
    }

    @include media-breakpoint-down('xs') {
      width: 100vw;
    }
  }

  &__children-wrapper-new {
    @include media-breakpoint-up('md') {
      width: 450px !important;
    }

    @include media-breakpoint-down('md') {
      width: 450px;
    }

    @include media-breakpoint-down('sm') {
      width: 70vw;
    }

    @include media-breakpoint-down('xs') {
      width: 100vw;
    }
  }

  &__header {
    font-size: 16px;
    background: $gray1;
  }

  &__divider {
    border-bottom: 2px solid #E4E9EF;
    width: 100%;
  }

  &__new-header {
    font-family: 'Inter Semibold';
    font-size: 20px;
    border-bottom: 2px solid #E4E9EF;

    &.tips {
      border-bottom: none;
    }
  }

  &__body {
    flex: 1 1 100%;
    overflow: hidden;
    overflow-y: auto;

    @include scrollbar(5px, $gray2);
  }

  &__notification {
    overflow: hidden;
    overflow-y: auto;
    transition: all .2s ease-in;
    @include scrollbar($gray1, 3px);

    &.open {
      max-height: 20%;
      opacity: 1;
      padding-bottom: 33px;
    }

    &.closed {
      transition: all .2s ease;
      height: 0;
      border: none;
      padding: 0;
      opacity: 0;
    }

    &-error {
      border-top: 5px solid $danger-primary;
      background-color: $danger-secondary;
    }

    &-warning {
      border-top: 5px solid $warning-primary;
      background-color: $warning-secondary;
    }

    &-info {
      border-top: 5px solid $info-primary;
      background-color: $info-secondary;
    }

    &-success {
      border-top: 5px solid $success-primary;
      background-color: $success-secondary;
    }

    &-text {
      color: $title;
      font-size: 1.5rem;
    }
  }

  &__bottom {
    height: 5.5rem;
    border-top: 1px solid $gray1;

    .required-message {
      font-size: 1.4rem;
    }

    &-buttons {
      button:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  .section-collapse {
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    padding: 0 !important;

    &.show {
      -moz-transition: all .2s ease-in;
      -ms-transition: all .2s ease-in;
      -o-transition: all .2s ease-in;
      -webkit-transition: all .2s ease-in;
      transition: all .2s ease-in;
      max-height: 250vh;
      overflow: hidden;
      visibility: visible;
      opacity: 1;
    }
  }

  &-information {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);

    &.closed {
      z-index: -1;
      opacity: 0;
    }

    &.open {
      z-index: 1;
      opacity: 1;
    }

    .modal__header {
      font-size: 16px;
      background: $gray1;
    }

    &-body {
      width: 90%;
      min-height: 5rem;
      box-shadow: 0px 2px 6px -2px #0000000D;
      box-shadow: 0px 10px 15px -3px #0000001A;
      border-radius: 16px;
      padding: 16px;
      background-color: $white;

      &-info {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
      }

      &-actions {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 2.4rem;
      }
    }
  }

  &-modal {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);

    &.closed {
      z-index: -1;
      opacity: 0;
    }

    &.open {
      z-index: 1;
      opacity: 1;
    }

    .modal__header {
      font-size: 16px;
      background: $gray1;
    }

    &-body {
      width: 90%;
      min-height: 5rem;
      box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.3);
      border-radius: 5px;
    }
  }

  @import 'sidemodals/edit-item';
  @import 'sidemodals/item-category';
}

@import 'sidemodals/company';
@import 'sidemodals/contact'