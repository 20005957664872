.electronic-invoice-loading-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(6px); /* Aplica desenfoque al fondo detrás */
  -webkit-backdrop-filter: blur(6px);
  z-index: 15;
  border-radius: 12px;
  flex-direction: column;
  gap: 0.5rem;
}

.form__invoice {
  &-divider {
    &-text {
      margin: 0 auto;
      width: fit-content;
      z-index: 1;
      background: $white;
      padding: 0 0.5rem;
    }

    &-line {
      width: 100%;
      display: block;
      position: absolute;
      top: 50%;
    }
  }

  &-cash-options {
    justify-content: space-between;
    margin-top: 2.55em;

    &.mobile {
      margin-top: 1rem;
      gap: 1rem;
      flex-direction: column;

      & > button {
        min-height: 48px;
      }
    }

    button {
      min-width: unset !important;
      margin-bottom: 1rem;
      width: 100%;
      flex: 0 1 100%;
      border-radius: 12px;
      border: 1px solid #cbd5e1 !important;
      height: 48px;
      color: #0f172a !important;

      @include media-breakpoint-up('sm') {
        width: 30%;
        flex: 0 1 30%;
      }
    }
  }

  &-qr-zone {
    width: 200px;
    height: 200px;
    border: 1.9px solid #00b19d;
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }

  &-advanced-data {
    border-left: 1px solid #94a3b866;

    &.mobile {
      border-left: none;
    }
  }
}

.s-overlay {
  z-index: 99 !important;
  &:has(.new-seller) {
    z-index: 10000 !important;
  }
}

.modal__invoice {
  .form-body .form-group .input-number-wrapper,
  .company-form-body .form-group .input-number-wrapper {
    border-radius: 12px;
    border: 1px solid #cbd5e1;
    input {
      border-radius: 12px;
    }
  }

  &-body {
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .form-body,
  .company-form-body {
    overflow-y: auto !important;
    @include scrollbar(5px, $gray2);

    .form-group {
      label {
        &.required {
          &::after {
            display: none;
          }
        }
      }
      label {
        &.required > p {
          &::after {
            content: ' *';
            color: $primary;
          }
        }
      }
    }
  }

  .text-change {
    color: $slate-500;
  }

  &-combined {
    &-table {
      width: 100%;

      &-container {
        overflow-x: auto;

        & > .mobile {
          min-width: 80rem;
        }
      }

      & tr {
        color: $slate-700;
        &:first-child th:first-child {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }
        &:last-child th:last-child {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
      & th {
        background-color: $slate-50;
        width: 33%;
        padding: 8px 16px;

        & p::after {
          content: '*';
          color: #30aba9;
        }
      }
      & td {
        & > .col-sm-12 {
          padding: 0px;
        }
        & > div > .col-sm-12,
        .col-sm-10 {
          padding: 0px;
        }
      }
    }
    &-btn {
      &-add > button > p {
        margin-bottom: 0 !important;
      }
      &-remove {
        margin-bottom: 1rem;
      }
    }
  }

  .change-payment {
    &-btn {
      display: flex;
      position: absolute;
      right: 16px;
      top: 16px;

      &.mobile {
        right: -1px;
      }
    }
  }

  .payment-methods {
    &-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-top: 1rem;
      margin-bottom: 2rem;
      &-mobile {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 1rem;
        margin-bottom: 2rem;
        max-height: 40rem;
        overflow-y: auto;
        @include scrollbar(5px, $gray2);
        & > .mobile {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }
      }

      & > .mobile {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
    }
    &-main {
      display: flex;
      justify-content: space-between;
      padding: 0 8rem;
    }
    &-secondary {
      display: flex;
      justify-content: space-between;
      padding: 0 8rem;
      &.incomplete {
        justify-content: center;
        gap: 2rem;
      }
    }
    &-carousel {
      position: relative;

      &-button {
        &-next {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -20px;
          z-index: 100;
        }

        &-prev {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: -20px;
          z-index: 100;
        }
      }
    }
  }

  .btn-invoice-method {
    &-border {
      width: 120px;
      height: 100px;
      border-radius: 12px;
      border: 1px solid $slate-300;
      background-color: white;
      padding-top: 1rem;

      &-mobile {
        width: 100%;
        display: flex;
        align-items: center;
        border: 1px solid $slate-300;
        padding: 1rem 1.5rem;
        border-radius: 12px;
        gap: 1rem;
      }

      &.selected {
        border: 2px solid #30aba9;
        p {
          color: $slate-700;
        }
      }

      &:hover {
        border: 2px solid #30aba9;
        p {
          color: $slate-700;
        }
      }

      // @media screen and (max-width: 769px) {
      //   width: 100%;
      //   height: 56px;
      //   padding: 12px 16px;
      // }
    }
    &-text {
      color: $slate-500;
      p {
        line-height: 16px;
      }

      @media screen and (max-width: 769px) {
        color: $slate-700;
      }
    }
    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 48px;
      & > img {
        height: 48px;
        object-fit: contain;
      }

      &-point {
        right: 0;
      }
    }
  }

  &-custom-payment {
    &-instruction {
      background: $slate-50;
      padding: 1.5rem !important;
      border-radius: 1rem;
    }
  }

  .bottom-invoice {
    &-container {
      gap: 8px;

      &.mobile {
        justify-content: center;
        & > button {
          width: 48%;
        }
        & > button:only-child {
          width: 100%;
        }
      }
    }
  }
}

.react-datepicker-popper {
  z-index: 100 !important;
}

.swiper-container {
  margin-left: 1em !important;
  margin-right: 1em !important;
}

.button-prev,
.button-next {
  border-radius: 50%;
  padding: 0.5rem;
  box-shadow: 0 1px 2px $gray3;
}

.side-modal__notification.open {
  padding-bottom: 5px;
}

.credit-limit {
  &-container {
    display: flex;
    gap: 1.5rem;
    background: $amber-100;
    border-radius: 1rem;
    margin: 0px 1rem;
    padding: 1.5rem 12px 8px 8px;
    border: 1px solid #fbbf2433;
    box-shadow:
      0px 2px 6px -2px #0000000d,
      0px 10px 15px -3px #0000001a;

    & a {
      color: $amber-700 !important;
    }
  }
}
