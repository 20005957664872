.react-datepicker {
  font-family: inherit !important;
  font-size: 1.2rem;

  &-popper {
    z-index: 5;
  }

  &__header {
    font-size: 1.25rem;
  }

  &__input-container {
    width: 100%;

    input {
      width: 100%;
      font-size: 14px;
      border-radius: 5px;
      border: 1px solid $gray3;
      color: $subtitle;
      height: 3rem;
      padding: 0 1rem;
    }
  }

  &__current-month {
    font-size: 1.5rem;
  }

  &__navigation {
    line-height: 1.5rem;
  }
  
  &__day-name, &__day, &__time-name {
    color: $title;
    width: 2.5rem !important;
    line-height: 2.5rem !important;
  }

  &__day--selected, &__day--in-selecting-range, &__day--in-range, &__month-text--selected, &__month-text--in-selecting-range, &__month-text--in-range, &__quarter-text--selected, &__quarter-text--in-selecting-range, &__quarter-text--in-range, &__year-text--selected, &__year-text--in-selecting-range, &__year-text--in-range {
    border-radius: 0.3rem;
    background-color: $primary;
    color: $white;
    
    &:hover {
      border-radius: 0.3rem;
      background-color: $primary-dark;
      color: $white;
    }
  }

  &__day--keyboard-selected, &__month-text--keyboard-selected, &__quarter-text--keyboard-selected, &__year-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: $primary;
    color: $white;
    
    &:hover {
      border-radius: 0.3rem;
      background-color: $primary-dark;
      color: $white;
    }
  }

  &__day--disabled {
    color: $gray3;
    cursor: default;
  }
}