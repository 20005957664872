.membership-title {
  width: 362px;
  height: 42px;
  left: 128px;
  top: 96px;

  h1 {
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 42px;

    color: $blue-dark;
  }
}

.current-plan-button {
  p {
    background-color: $primary;
    color: $white;
    width: fit-content;
    padding: 0.5rem 2rem;
    border-radius: 0.5rem;
  }
}

.current-plan-button:hover {
  cursor: pointer;
}

.consumption-summary {
  border-radius: 5px;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);

  .consumption-summary-block {
    width: 20%;
    height: 7rem;
    border-right: 2px solid #f4f4f4;
    justify-content: space-evenly;

    .current-plan {
      font-style: normal;
      font-weight: 500;
      font-size: 2.8rem;
      line-height: 3.3rem;
    }

    .feature {
      font-style: normal;
      font-weight: 500;
      font-size: 2.4rem;
      line-height: 2.8rem;

      text-align: center;
    }
  }

  .consumption-summary-block:last-child {
    border-right: 0;
  }

  @include media-breakpoint-down("md") {
    .consumption-summary-block {
      width: 25%;
      .current-plan {
        p {
          padding: 0.5rem 1rem;
        }
      }
    }
    .consumption-summary-income {
      display: none !important;
    }
  }

  @include media-breakpoint-down("xs") {
    padding: 0.5rem !important;

    .consumption-summary-block {
      .current-plan {
        p {
          padding: 0.5rem 0.5rem;
        }
      }
    }
  }
}

.consumption-details {
  @include media-breakpoint-down("sm") {
    flex-direction: column !important;

    .general-consumption {
      width: 100% !important;
    }

    .created-users {
      width: 100% !important;
    }
  }
}

.general-consumption {
  width: 40%;
  border-radius: 5px;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);

  .title {
    border-bottom: 1px solid #f2f2f2;

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;

      color: $blue-dark;
    }
  }

  .general-consumption-divider {
    border-bottom: 1px solid #f2f2f2;
  }
}

.created-users {
  width: 57%;
  border-radius: 5px;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);

  .title {
    border-bottom: 1px solid #f2f2f2;

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;

      color: $blue-dark;
    }
  }

  .react-table {
    font-family: Inter;
  }

  .react-table__th {
    background: #f8fafc;
    color: $blue-dark;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.8rem;

    border: none;
  }

  .react-table__td {
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem !important;
    line-height: 1.8rem;

    p {
      padding: 0;
      margin: 0;
    }
  }

  .react-table__th:not(:last-child) {
    border-right: 0 !important;
  }

  .react-table__th:last-child {
    text-align: center !important;
    border-radius: 0px 10px 10px 0px;
  }

  .react-table__th:first-child {
    border-radius: 10px 0px 0px 10px;
  }

  .react-table__td:not(:last-child) {
    border-right: 0 !important;
  }

  .react-table__td:first-child {
    color: $blue-dark;
  }

  .react-table__td:last-child {
    text-align: center;
  }

  .react-table__body-tr {
    border-top: 0 !important;
    border-bottom: 1px solid #e1e8ef !important;
  }

  .react-table__body-td {
    border: none !important;
  }

  .react-table__head {
    box-shadow: none !important;
  }

  .react-table__pagination {
    display: none !important;

    button {
      font-size: 1rem !important;
      height: 3rem;
      min-width: 5rem !important;
    }

    div {
      font-size: 1rem !important;
      border-top: 0 !important;
    }

    .react-table__pagination-indicator input {
      padding: 1px 7px;
    }

    div {
      align-items: center !important;
    }
  }

  @include media-breakpoint-down("xs") {
    .react-table__pagination div {
      flex-direction: row !important;
    }

    .pagination-description {
      display: none;
    }
  }
}
