.item-category-container {
  margin: 1rem 0;
  background: $gray1;
  aspect-ratio: 1;
  height: calc(3.8rem * 4.5);
  border: 2px $secondary dashed;
  border-radius: 5px;

  img {
    border-radius: 5px;
  }
}