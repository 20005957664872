.react-table-simplified {
  font-family: "Inter";
  border-right: 1px solid #e1e8ef;
  height: 100%;
  th,
  td {
    border: 0px !important;
  }
  &__table {
    margin: 0;
    border-collapse: collapse !important;
    border-radius: 10px 10px 0px 0px !important;
    overflow: hidden;
  }

  @include scrollbar(5px, $primary);

  &__head {
    background: $white;
    color: #617589;
    font-size: 1.4rem;

    tr {
      border: 1px solid #e1e8ef;
      border-top: 0px;
      height: 4rem;
    }
    tr:first-child {
      border-radius: 10px 10px 0px 0px !important;
      border: 1px solid #e1e8ef;
    }
  }

  &__th {
    display: flex;
    align-items: center;
    background: #f8fafc;
    color: black;
    margin-bottom: 0;
    font-size: 1.3rem;
    padding-left: 16px !important;
    &.sort-desc {
      box-shadow: inset 0 -3px 0 0 $primary-dark !important;
    }

    &.sort-asc {
      box-shadow: inset 0 3px 0 0 $primary-dark !important;
    }
  }

  &__th,
  &__td {
    flex: 1 1 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__td {
    font-size: 1.5rem;
    min-height: 3.6rem;
    padding-left: 16px !important;
  }

  &__body {
    background: $white;
    height: max-content;
    overflow: hidden;

    &-tr {
      height: 8rem !important;
      border-left: 1px solid #e1e8ef;
      // border-right: 1px solid #e1e8ef;

      &:not(:last-child) {
        border-bottom: 1px solid #e1e8ef;
      }

      &-active {
        height: 8rem !important;
        border-left: 1px solid #e1e8ef;
        background: linear-gradient(
            0deg,
            rgba(226, 232, 240, 0.45),
            rgba(226, 232, 240, 0.45)
          ),
          #ffffff;

        &:not(:last-child) {
          border-bottom: 1px solid #e1e8ef;
        }
      }
    }

    &-td {
      border-color: $gray1 !important;
      display: flex !important;
      align-items: center;
      div {
        justify-content: right;
      }
    }

    &.no-data {
      height: 36rem;
    }
  }

  &__loading {
    height: 60vh;

    background: rgba(255, 255, 255, 0.75);
    z-index: 1;
  }

  &__no-data {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.75);
    z-index: 1;
  }

  &__pagination {
    min-height: 4.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #e1e8ef;
    border-radius: 0px 0px 10px 10px !important;

    &-container {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      align-content: center;
      height: 100% !important;

      .carrent {
        &__simple {
          background: $white;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #c9d5e0;
          border-radius: 8px;
          cursor: pointer;
        }
        &__doble {
          background: $empty;
          width: 44px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          cursor: pointer;
        }
      }

      input {
        width: 5rem;
        border: 1px solid #cccccc;
        border-radius: 4px;
        background: #fff;
        padding: 2px 8px;
        font-size: 12px;
        font-weight: normal;
        outline: none;
        color: $subtitle;
      }

      .description {
        color: #304254;
        font-weight: 500;
        font-size: 14px;

        @include media-breakpoint-down("md") {
          display: none;
        }

        @include media-breakpoint-down("sm") {
          display: none;
        }

        @include media-breakpoint-down("xs") {
          display: none;
        }
      }

      .results {
        font-weight: 300;
        color: #304254;
        font-size: 14px;
        font-weight: 400;
        padding: 0px 5px;

        @include media-breakpoint-down("sm") {
          width: 10rem;
          font-size: 12px;
          display: inline;
        }

        @include media-breakpoint-down("xs") {
          display: inline;
        }
      }

      .reload {
        background: $white;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #c9d5e0;
        cursor: pointer;
      }

      .options {
        width: 8.5rem;
        border-right: 2px solid #f0f3f7;
      }

      @include media-breakpoint-down("xs") {
        justify-content: space-around;
        align-items: center;
        padding: 1rem;
      }
    }
  }
}

.styled-hover:hover::before {
  content: attr(hovercontent);
  position: absolute;
  padding: 10px;
  border: 1px solid $gray1;
  background: $white;
  font-size: 12px;
  color: $blue-dark;
  text-align: center;
  transform: translateY(-40px);
  border-radius: 10px;
  box-shadow: 5px 5px 5px $gray1;
  z-index: 10;
  transition: 1s;
}
