.confirm-action-modal {
    width: 48rem;
    padding: 1.6rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    &-body {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
    }

    @include media-breakpoint-down('md') {
        width: 75rem;
    }

    @include media-breakpoint-down('sm') {
        width: 100vw;
        justify-content: center;
    }

    &-actions {
        display: flex;
        justify-content: flex-end;
        gap: 1.6rem;
    }

}