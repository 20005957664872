.switch-ofline-ticket-container {
  width: 100%;
  max-width: 1030px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 25px;
  padding-bottom: 10px;

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .switch-container {
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    & > div {
      display: flex;
      width: 38px;
      justify-content: center;
    }

    .loader {
      position: absolute;
      top: -25px;
    }
  }
}
