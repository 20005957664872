.modal__station-settting {
  @include media-breakpoint-up('md') {
    max-width: 50rem;
    width: 50rem;
  }
  
  @include media-breakpoint-down('sm') {
    width: 50rem;
  }

}

.modal__station-settting-overlay {
  z-index: 101;
}