.detail-header {
  background: transparent;

  .new-refund-button-container {
    margin-top: -1rem;
    background-color: #f4f5fb;

    button {
      font-size: 12px;
      height: 35px;
      padding: 4px 8px;
    }
  }

  .new-refund-header {
    background-color: #fff;
    border-radius: 8px;

    .title {
      display: flex;
      align-items: center;

      .title-main {
        margin-left: 8px;
        color: $slate-700;
      }

      .title-secondary {
        margin-left: 0.7rem;
        color: #A0ADC0;
      }
    }

    &_buttons {
      button {
        min-height: auto;
        border-radius: 5px;
        font-size: 12px;
        height: 24px;
        padding: 0 6px;

        svg {
          margin-right: 4px;
        }

        &:last-child {
          margin-left: 12px;
        }
      }
    }
  }
}

.detail-body {
  table.detail-info-table {
    table-layout: fixed;
    margin-bottom: 22px;

    tr {
      height: 32px;

      td {
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
      }

      td.caption-bold {
        width: 200px;
      }

      td.emission-status {
        &-closed {
          color: $primary-600;
        }

        &-open {
          color: $danger;
        }

        &-void {
          color: $secondary;
        }

        &-process {
          color: #4A90E2;
        }
      }
    }
  }

  table.detail-items-table {
    th {
      height: 30px;
      text-transform: none;
      font-size: 12px;

      &:first-child,
      &:last-child {
        border-radius: 0;
      }
    }

    tr {
      td {
        height: 32px;
        font-size: 12px;
      }

      td:first-child {
        max-width: 173px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  table.detail-summary-table {
    .body-2-bold {
      text-align: start;
      line-height: 19px;
    }

    .heading-3 {
      text-align: start;
    }
  }
}