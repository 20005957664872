$menuWidth: 30rem;

.notification-tip-tooltip {
  color: #FFFFFF;


  h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  p {
    font-size: 12px;
    margin-bottom: 16px;
  }

  .rc-tooltip-inner {
    padding: 16px !important;
    position: relative;
  }

  .close-icon {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  .btn {
    background-color: #FFFFFF;
    color: #000000;
  }
}

.table-form-remove-record-button {
  position: absolute;
  right: 10px;
  top: 16px;
}


[type=button]:has(svg) {
  display: flex;
  gap: 0.4rem;
  justify-content: center;
  align-items: center;
}

.notification-error-list {

  ul {
    padding-left: 20px;
  }

  li {
    list-style: disc;
  }
}


.absolute-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &-select-station {
    width: 50rem;

    @include media-breakpoint-down('xs') {
      width: 95%;
    }
  }

  .badge-light {
    background-color: $primary-50;
    display: flex;
    gap: 0.4rem;
    border: 1px solid $primary-700;
    align-items: center;
    width: fit-content;
    height: fit-content;
    cursor: pointer;

    p {
      color: $primary-700;
    }

    svg {
      color: $primary-700;
    }
  }

  &-error {
    width: 60rem;

    img {
      width: 55%;
    }

    &-img-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 4rem;
    }

    .btn {
      padding: 0;
    }

    li {
      margin-bottom: 1rem;
    }

    li::marker {
      color: $primary;
      font-size: 1.5em;
    }

    @include media-breakpoint-down('xs') {
      width: 95%;
    }
  }

  .navigation {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $shadow-background;
    z-index: 101;

    &.closed {
      z-index: -1;
      transition: z-index 0.1s linear;
      transition-delay: 0.2s;

      .menu {
        transform: translateX(calc(-1 *#{$menuWidth}));

        @include media-breakpoint-down('xs') {
          transform: translateX(calc(-1 * 100vw));
        }
      }
    }

    .menu {
      height: 100%;
      background: $white;
      width: $menuWidth;
      transition: transform 0.2s ease-in-out;

      @include media-breakpoint-down('xs') {
        width: 100vw;
      }
    }

    @import 'navigation';
  }

  .new-navigation {
    @extend .navigation;

    background: $slate-700-60;

    &.closed {
      z-index: -1;
      transition: z-index 0.1s linear;
      transition-delay: 0.2s;

      .new-navigation-menu {
        transform: translateX(calc(-1 *#{$menuWidth}));

        @include media-breakpoint-down('xs') {
          transform: translateX(calc(-1 * 100vw));
        }
      }
    }

    &-menu {
      height: 100%;
      width: 34rem !important;
      background: $white;
      width: $menuWidth;
      transition: transform 0.2s ease-in-out;
      display: flex;
      flex-direction: column;

      @include media-breakpoint-down('xs') {
        width: 100vw;
      }

      &-disabled {
        background-color: $slate-100;
      }

      &-action {
        cursor: pointer;
        transition: 0.5s all;
        justify-content: space-between;

        &:hover {
          background-color: $slate-100;
        }
      }

      &-header:first-child {
        height: 56px;
      }

      &-header {
        padding: 0.4rem 1.6rem;
        height: 48px;
        width: 100%;
        border-bottom: 1px solid $slate-200;
        display: flex;
        align-items: center;
        gap: 0.8rem;

        &-helper {
          height: 48px;
        }
      }

      .menu-footer {
        border-top: 1px solid $slate-200;
        display: flex;
        height: 8rem;
        padding: 1.6rem;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

.medium-icon {
  width: 35px;
}

.small-icon {
  width: 18px;
}

.x-small-icon {
  width: 12px;
}

.text-small {
  font-size: 1.2rem;
}

.hidden {
  visibility: hidden;
}

.input-date-picker {
  position: inherit !important;
}

.vertical-separator {
  width: 1px;
  height: 24px;
  background-color: $slate-200;
}

.container-light {
  border: 1px solid $slate-200;
  background-color: $white;
  padding: 3.2rem;
  border-radius: 1.6rem;

  @include media-breakpoint-down('sm') {
    padding: 0;
  }
}

.op-50 {
  opacity: 0.5;
}

.alignment-right {
  right: 0;
}

.alignment-left {
  left: 0
}

.position-top {
  top: -10rem !important;
}

.menu-select-item {
  position: absolute;
  box-shadow: 0px 2px 6px -2px #0000000d;
  box-shadow: 0px 10px 15px -3px #0000001a;
  background: $white;
  border-radius: 1.6rem;
  min-width: 18rem;
  top: 4rem;
  z-index: 10;

  &-title {
    width: 100%;
    text-align: start;
    padding: 0.6rem 0.8rem;
    border-radius: 0.8rem;
    padding-bottom: 0;
  }
}

.mobile-padding {
  @include media-breakpoint-down('sm') {
    padding: 0 2rem;
  }
}

.header-breadcrumb {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.custom-checkbox::after {
  border: 1px solid $slate-300 !important;
}

.custom-checkbox:checked:after {
  border: none !important;
}

.custom-sidebar-logo {
  height: 40px;
  width: auto;
}

.container-mobile {
  overflow-y: auto;
  height: 100%;
  @include scrollbar(5px, $gray2);

  @include media-breakpoint-down('sm') {
    padding: 0;

    &>div {
      padding: 0;
    }
  }
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.4rem;
}

.gap-3 {
  gap: 0.8rem;
}

.dashed-line {
  border-top: 2px dashed $slate-300;
  width: 100%;
  height: 1px;
}

.w-60 {
  width: 60%;

  @include media-breakpoint-down('md') {
    width: 100%;
  }
}

.w-80 {
  width: 80% !important;

  @include media-breakpoint-down('md') {
    width: 100%;
  }
}

.column-mobile {
  @include media-breakpoint-down('md') {
    flex-direction: column;
  }
}

.custom-notification-offline {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  padding: 0 1.6rem;
  background-color: #fef3c7;
  height: 48px;

  @include media-breakpoint-down('md') {
    height: 88px;
    text-align: center;

    svg {
      display: none;
    }
  }
}

.w-fit {
  width: fit-content;
}

.text-decimal-tertiary label {
  color: rgb(112, 112, 112);
  font-weight: 400;
  font-size: 1.2rem !important;
}

.s-price-input {
  .s-input-decimal__container.small {
    height: 32px !important;
    border-radius: 8px !important;

    .s-input-decimal__input {
      height: 100%;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    background-color: transparent;
    outline: 2px solid transparent;
    outline-offset: 2px;
    padding: 6px 8px;
    }
  }
}

form {
  .s-input-decimal {
    .s-input-decimal__label {
      height: 25px;
      font-size: 1.2rem;
      margin-bottom: 0;
      font-weight: 400;
      color: rgb(112, 112, 112);
  
      .s-input-decimal__mark {
        color: #00b19d;
      }
    }
  
    p.typography {
      color: #6c757d !important;
      margin-bottom: 0.5rem !important;
      font-weight: 500;
      line-height: 1.2;
      font-size: 1.25rem;
    }
  
    .s-input-decimal__container {
      margin: 0 !important;
      height: 3.6rem;
      border-radius: 6px !important;
    }
  
    .s-input-decimal__input {
      display: block;
      width: 100%;
      height: 3.4rem;
      padding: 0.375rem 0.75rem;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.5;
      color: #707070;
      background-color: #fff;
      background-clip: padding-box;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  }
}
