.new-invoice-saved {
  .btn-send-email {
    border-left: 1px solid $slate-300;
    padding: 0.375rem 1rem;
    min-width: 10rem;

    &:disabled {
      background-color: $slate-50;
      opacity: 30%;
    }
  }

  .bottom-invoice {
    &-container {
      gap: 8px;

      &.mobile {
        justify-content: center;
        & > button {
          width: 48%;
        }
        & > button:only-child {
          width: 100%;
        }
      }
    }
  }

  &-total {
    &-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      background-color: #f8fafc;
      border-radius: 12px;
    }
  }

  .dot-flashing {
    position: relative;
    bottom: -17px;
    right: -2px;
    width: 4px;
    height: 4px;
    border-radius: 5px;
    background-color: $slate-700;
    color: $slate-700;
    animation: pointsAnimation 1s infinite linear alternate;
    animation-delay: 0.5s;
  }
  .dot-flashing::before,
  .dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .dot-flashing::before {
    left: -7px;
    width: 4px;
    height: 4px;
    border-radius: 5px;
    background-color: $slate-700;
    color: $slate-700;
    animation: pointsAnimation 1s infinite alternate;
    animation-delay: 0s;
  }
  .dot-flashing::after {
    left: 7px;
    width: 4px;
    height: 4px;
    border-radius: 5px;
    background-color: $slate-700;
    color: $slate-700;
    animation: pointsAnimation 1s infinite alternate;
    animation-delay: 1s;
  }

  .btn.disabled,
  .btn:disabled {
    opacity: 0.45 !important;
  }
}
