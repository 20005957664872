.filter-tab-container {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.4rem;
    margin-bottom: 1rem;

    @include media-breakpoint-down('sm') {
        margin-bottom: 0;
    }

    &-body {
        display: flex;
        overflow: hidden;


        &-content {
            display: flex;
            transition: transform 0.3s;

            @include media-breakpoint-down('sm') {
                overflow-x: scroll;
                @include scrollbar(0, $gray3);
            }

            &-item {
                cursor: pointer;
                height: 4rem;
                padding: 0.8rem 1.6rem;
                border-bottom: 1px solid $slate-300;

                &>p {
                    white-space: nowrap;
                }

                &.selected {
                    border-bottom: 2px solid $primary-600;
                }
            }
        }


    }
}