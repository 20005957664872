.global-invoices-header-text span {
  &:nth-child(2) {
    color: $primary-600;
  }

  @include media-breakpoint-down('sm') {
    display: block;
  }
}

.numerations-tooltip {
  height: 6rem;
  display: flex;
  align-items: flex-end;
  padding: 0;
  padding-bottom: 0.6rem;
}

.global-invoices-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 0.5rem;

  @include media-breakpoint-down('sm') {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 2rem;
    padding: 0 2rem;
    margin-top: 0;

    &-filter {
      width: 100%;

      &>div>div {
        width: 100%;
      }
    }
  }
}

.custom-global-invoices-form-header {
  @include media-breakpoint-down('sm') {
    padding: 2rem;

    &-title {
      margin-bottom: 1rem;
    }

    &-month {
      width: 70%;
      padding-right: 0.5rem;
    }

    &-year {
      width: 30%;
      padding-left: 0.5rem;
    }

    &-numeration {
      width: 100%;
      justify-content: space-between;

      &>div:first-child {
        width: 90% !important;
      }

      &>div:last-child {
        width: 8% !important;
        margin-top: 0.4rem;
      }
    }
  }
}

.global-invoices-detail-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 3rem;

  @include media-breakpoint-down('sm') {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 2rem;
    margin-top: 2rem;
    width: 100%;
    padding: 0 2rem 2rem 2rem;

    .global-invoices-detail-resume {
      max-width: 100%;
    }
  }
}

.global-invoices-detail-resume {
  background-color: $slate-50;
  border: 1px solid $slate-200;
  border-radius: 0.8rem;
  padding: 1.6rem 1.6rem 2.4rem 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  max-width: 32rem;
  width: 100%;
  height: 100%;
}
