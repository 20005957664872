.main-notification {
  padding: 1.5rem;
  padding-bottom: 0.5rem;

  &__notification {
    border-radius: 8px;
    padding: 0.7rem !important;
    
    @include media-breakpoint-down('md') {
      flex-direction: column;
    }

    &-info {
      font-size: 1.4rem;
      width: 70%;

      @include media-breakpoint-down('md') {
        width: 100%;
      }

      b {
        line-height: 2rem;
        color: $slate-900;
        font-weight: 600;
      }

      p {
        line-height: 2rem;
        color: $slate-700;
      }
    }

    &-actions {
      @include media-breakpoint-down('md') {
        padding-left: 5.4rem;
      }

      .close-action-button {
        @include media-breakpoint-down('md') {
          position: absolute;
          top: 2rem;
          right: 2rem;
        }
      }
    }
  }
}
