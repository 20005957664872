.action-buttons {
  font-family: "Inter";
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: transparent;

  @include media-breakpoint-down("xs") {
    justify-content: space-around;
  }
}

.single-action-button {
  font-family: "Inter";
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: transparent;

  @include media-breakpoint-down("xs") {
    justify-content: center;
  }
}
