&.tip-notification-modal {
  width: 440px;
  height: 400px;
  padding: 16px;
  border-radius: 15px;

  .tip-notification-modal__actions {
    margin-top: 70px;
    gap: 10px;

    button {
      width: 48%;
      margin: 0 5px;

      &:last-child {
        height: 50px;
      }
    }

  }

  .tip-notification-svg-container {
    position: relative;
    width: 90px;
    margin-top: 50px;

    .svg-bubble-tip {
      position: absolute;
      right: 0;
      top: -2px;
    }
  }



}