&.modal__invoice-provisional-ticket {
  width: 480px;
  max-width: 95%;
  color: #334155;
  border-radius: 16px;
  padding: 16px;

  .modal__invoice-provisional-ticket {
    &__icon {
      svg {
        width: 70px;
        height: 70px;
      }
    }

    &__total {
      background-color: #F8FAFC;
      border-radius: 10px;
      padding: 1rem;
    }

    &__message {
      margin-top: 15px;
      margin-bottom: 15px;
      display: flex;
      color: #0F172A;
      align-items: center;

      & p {
        margin-left: 1rem !important;
      }
    }

    &__bottom {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: flex-end;

      & button {
        height: 40px;
      }
    }

    &__print-button {
      padding: 10px 20px;
      border: 1px solid #CBD5E1;
      border-radius: 10px;
      margin-right: 10px;
      display: flex;
      align-items: center;

      & i {
        margin-right: 5px;
      }
    }

    &__print-button:hover {
      background-color: #F8FAFC;
    }
  }
}