.page-heading {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    margin: 0 0 2.4rem 0;

    @include media-breakpoint-down('sm') {
        margin: 0 1.6rem 2.4rem 1.6rem;
    }

    &-back {
        display: flex;
        align-items: center;
        gap: 0.4rem;
        cursor: pointer;
    }

    &-body {
        display: flex;
        justify-content: space-between;


        @include media-breakpoint-down('md') {
            flex-wrap: wrap;
            gap: 2rem;
        }

        &-info {
            display: flex;
            gap: 0.8rem;

            &-data {
                &-title {
                    display: flex;
                    gap: 0.8rem;
                    align-items: center;

                    @include media-breakpoint-down('md') {
                        flex-direction: column;
                        align-items: flex-start;
                    }
                }
            }

            @include media-breakpoint-down('md') {
                width: 100%;
            }
        }

        &-actions {
            display: flex;
            gap: 0.8rem;
            height: 100%;
        }

    }
}