&.modal__migrate-to-alegra {
  position: relative;
  & .close-button {
    position: absolute;
    right: 3rem;
    top: 2rem;

    &-cri {
      cursor: pointer;
      width: 4rem;
      height: 4rem;
      position: absolute;
      right: 1rem;
      top: 1rem;
    }
  }

  & .see-prices-button {
    width: 168px;
    height: 48px;
    background-color: transparent;
    position: absolute;
    top: 79%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    &.spain {
      top: 83%;
    }
    &.mobile {
      width: 111px;
      height: 32px;
      top: 84%;

      &.spain {
        top: 79%;
      }
    }
  }

  & .terms-and-conditions {
    position: absolute;
    top: 92%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 260px;
    height: 20px;
    cursor: pointer;

    &.spain {
      top: 94%;
    }

    &.mobile {
      width: 185px;
      height: 13px;
      top: 94%;
    }
  }
}
