.custom-breadcrumb {
    display: flex;
    gap: 0.25rem;

    &-item {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        cursor: pointer;

        p {
            color: $primary-700;
        }

        &:last-child {
            cursor: default;

            p {
                color: $slate-500;
            }

            svg {
                display: none;
            }
        }


    }
}