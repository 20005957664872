.statistics {
  overflow: auto;
  @include scrollbar(5px, $primary);
}

.statistics-menu {

  &__option {
    flex: 0 0 22rem;
    min-height: 27rem;
    max-height: 27rem;
    background: $white;
    border-radius: 6px;
    border-bottom: 3px $primary solid;
    margin: 0 1rem 2rem;
    padding: 1rem;

    &-header {
      font-weight: 500;
      padding-bottom: .5rem;
      margin-bottom: .5rem;
      border-bottom: 1px solid $gray1;
    }

    &-description {
      font-size: 1.2rem;
      margin-bottom: .5rem;
      max-height: 6rem;
      min-height: 6rem;
    }

    &-options {
      font-size: 1.4rem;

      a:not(:last-child) {
        padding-bottom: .5rem;
      }
    }
  }
}

.chart-wrapper {
  position: relative;
  min-height: 350px !important;
  max-height: 350px !important;

  @include media-breakpoint-down('xs') {
    min-height: 300px !important;
    max-height: 300px !important;
  }
}

.chart-data-indicator {
  position: absolute;
  background: rgba($white, 0.7);
  top: 0;
  bottom: 0;
}