@import 'master-detail';

.main {
  flex: 1 1 100%;
  background: $empty;
  overflow: hidden;

  @import 'home';
  @import 'invoices';
  @import 'shifts';
  @import 'refunds';
  @import 'payments';
  @import 'plans';
}

.main-new-color {
  background: $slate-100;
}