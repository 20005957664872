.onboarding {
  &-inner {
    max-width: 100rem;
    width: 90%;
    border-radius: 5px;
  }

  form {
    height: 100%;
    max-height: 100%;
  }

  &-header {
    z-index: 1;
    height: 6rem;
    width: 100%;
    box-shadow: 0 1px 2px $gray1;
    background: $white;
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &-body {
    height: 100%;
    max-height: 100%;
    overflow-y: auto;

    @include scrollbar(5px, $gray1);
  }

  &-inner-body {
    .form-body {
      padding: 2rem 0 1rem;
      width: 100%;
      
      .divider {
        margin: .5rem 0;
        height: 1px;
        background: $gray1;
        width: 100%;
        max-width: 50rem;
        flex: none;
      }
    }

    .form-body__fields {
      margin-top: 1.5rem;
      max-width: 35rem;
    }

    .video-intro-outer {
      width: 95%;
      padding-top: 1rem;
      max-width: 65rem;
      border-radius: 7px;

      .video-intro {
        border-radius: 7px;
        overflow: hidden;
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;

        iframe, object, embed {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  
  &-bottom {
    height: 7.5rem;
    width: 100%;
    box-shadow: 0 -1px 2px $gray1;
    background: $white;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    .bottom {
      height: 100%;
      max-width: 100rem;
    }
  }

  .onboarding-subtitle {
    width: 70%;
  }
}