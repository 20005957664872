.setting {
  &__print {

    &-preview {
      background: $gray1;
      max-width: 100%;
      overflow: hidden;

      .print-80 {
        width: 80mm;
      }
      
      .print-57 {
        width: 57mm;
      }

      &-button {
        position: absolute;
        height: 5rem;
        width: 5rem;
        bottom: 1rem;
        right: 1rem;
        z-index: 1;
        border: 1px solid $primary;

        svg {
          height: 32px;
          width: 32px;
        }
      }

      &-zoom {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(51, 51, 51, 0.7);
        z-index: 10;

        &-wrapper {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          overflow: auto;
          z-index: 100;
          padding: 3rem 0;

          @include scrollbar(5px, $gray1);
        }

        .btn-close-preview-zoom {
          position: absolute;
          top: 20px;
          right: 20px;
          z-index: 101;
          transform: scale(2);
        }
        
        .btn-close-preview-zoom-control {
          position: absolute;
          bottom: 25px;
          right: 30px;
          z-index: 101;
          transform: scale(1.5);

          button {
            background: rgba(0, 0, 0, 0.6);
            box-shadow: unset;
          }
        }
      }
    }
  }
}