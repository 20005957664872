.share-invoice {
  &-btn {
    &-selected {
      border: #30aba9 1px solid !important;
    }

    &-send {
      border: 1px solid $slate-300;
      height: 48px;
      border-left: none !important;

      &.btn {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }
  }
}

.share-invoice-saved {
  .s-input__container {
    height: 48px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}
