&.step-3 {
  height: 655px;
  width: 480px;

  .new-refund-items-form {
    .items-form {
      &_subtitle {
        font-size: 14px;
      }

      &_items-container {
        display: flex;
        flex-direction: column;
        border: 1px solid #E2E8F0;
        border-radius: 12px;
        height: 486px;

        .items-container-header {
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
          background-color: #F8FAFC;
          padding: 12px;

          &_title {
            font-family: 'Inter Semibold';
            font-size: 16px;
            color: #334155;
          }
        }
      }

      &_items-caption {
        padding: 8px 12px;
        font-size: 12px;
        color: #64748B;
        margin: 0;
      }
    }

    .items-list {
      display: flex;
      align-items: center;
      flex-direction: column;
      overflow-y: auto;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      max-height: 250px;
      @include scrollbar(5px, $gray2);

      &.show-all {
        max-height: none;
      }

      .new-refund__item {
        margin: 0;
        border: 1px solid #E2E8F0;
        border-radius: 12px;
        width: 424px;
        animation: fadeIn ease .2s;
        -webkit-animation: fadeIn ease .2s;
        -moz-animation: fadeIn ease .2s;
        -o-animation: fadeIn ease .2s;
        -ms-animation: fadeIn ease .2s;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        .discount {
          font-family: 'Inter';
          font-size: 12px;
          height: 18px;
        }

        @include media-breakpoint-down('sm') {
          width: 300px
        }

        &:hover {
          background: #F8FAFC;
        }

        &-warning-container {
          padding-top: 0.25rem;
        }

        &-warning {
          color: #B45309;
          max-height: 32px;
          margin: 0px;
        }

        &-data {
          flex: 1 1 50%;
          padding: 0 5px 0 0;
        }

        &-total-price {
          flex: 1 1 25%;
          padding: 0 5px;
          height: 100%;
        }

        &-container-price-actions {
          min-width: 125px;
        }

        &-name {
          white-space: break-spaces;

          &.name-large {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }

        &-name,
        &-total-price {
          color: $title;
          font-family: 'Inter Semibold';
          font-size: 14px;
        }

        &-price,
        &-reference {
          color: $new-text-tertiary;
          font-weight: 500;
          font-size: 12px;
        }

        &-actions {
          position: absolute;
          visibility: hidden;
          display: flex;
          justify-content: right;
          right: 0;
          left: 0;
          width: 100% !important;
          height: 100%;
          background: #F8FAFC;
          margin-right: 1rem;

          &-button {
            cursor: pointer;
            width: 25px;
            height: 25px;
            border-radius: 50%;

            &.first {
              margin-right: 15px;
            }

            svg {
              width: 18px;
              height: 18px;
              margin: auto 0;
            }
          }

          &-button:hover {
            background-color: #F1F5F9;

            svg {
              color: black;
            }
          }

          &-button:active {
            background-color: #E2E8F0;
          }

        }

        &:hover .new-refund__item-actions {
          visibility: visible;
        }

        &-quantity-container {
          height: auto;
          left: 50%;
          top: 1.5rem;
          border-radius: 2rem;
          animation: fadeIn ease .2s;
          -webkit-animation: fadeIn ease .2s;
          -moz-animation: fadeIn ease .2s;
          -o-animation: fadeIn ease .2s;
          -ms-animation: fadeIn ease .2s;

          .icon-gray {
            path {
              fill: #24262a !important;
            }
          }

          svg {
            width: 25px;
            height: 25px;
          }

          svg:hover {
            rect {
              fill: #E2E8F0;
            }
          }

          svg:active {
            rect {
              fill: #CBD5E1;
            }
          }

          .disabled {
            color: rgb(36, 40, 48);

          }

          .disabled:hover {

            color: rgb(241, 245, 249);

          }
        }

        &-quantity {
          font-family: 'Inter';
          font-size: 16px;
          color: $new-text-secondary;
          font-weight: 500;
          margin: 0;
          width: 38px;

          input {
            width: 100%;
            border: none;
            outline: none;
            text-align: center;
            padding: 0;
            background-color: transparent;
          }
        }

        &-delete {
          width: 2.5rem;
          border-right: none;
          padding: 5px 0 !important;

          &:hover {
            background: $secondary !important;

            svg {
              color: $white !important;
            }
          }
        }
      }

      .new-refund__item-container {
        padding-bottom: 6px;
        align-self: center;

        &.selected {
          .new-refund__item {
            background: #e2e8f0;
          }
        }
      }

      .btn-md-default-filled {
        margin-left: 12px;
        align-self: flex-start;
        font-size: 14px;
      }
    }

    .inactive {
      background-color: #f1f5f9 !important;

      .new-refund__item-actions {
        background-color: #f1f5f9 !important;
      }
    }

    .items-summary {
      background: #F8FAFC;
      border-radius: 1rem;
      color: #8996A7;
      max-height: 114px;
      overflow-y: auto;
      @include scrollbar(5px, $gray2);


      .refund-bottom__summary {
        &-row {
          font-size: 1.3rem;
          padding: .7rem;
          color: $title;
          border-bottom: 1px dashed $gray2;

          .edit-tips {
            cursor: pointer;

            svg:hover {
              stroke: rgb(18, 21, 26);
            }
          }

          .btn-add-tip {
            font-size: 14px;
            color: $primary;
            padding: 0.7rem 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;
            border: none;
            border-radius: 10px;

            &:hover {
              background: #d3d7db3e;
            }
          }

          &:last-child {
            border: 0;
          }

          .desc {
            color: $new-text-tertiary;
          }

          .number {
            font-family: 'Inter Semibold';
            color: $new-text-secondary !important;
          }
        }
      }
    }

    .search-bar {
      height: 36px;
      width: 100%;
      margin: 12px;

      &>button {
        height: 100%;
        padding: 0 1.5rem;
        border-radius: 0;
        width: 56px;

        &:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
      }

      .search-bar-input {
        width: 100%;
        height: 100%;

        border: 1px solid $primary;
        border-left: none;
        color: $title;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 0 4rem 0 1rem;
        outline: none;
        font-size: 1.5rem;
      }

      .search-bar-container {
        position: relative;

        button {
          right: 0;
          top: 0;
          bottom: 0;
        }
      }
    }

    .refund-item-list {
      overflow: hidden;
      overflow-y: auto;
      @include scrollbar(5px, $gray2);

      .refund-item {
        border: 1px solid $gray1;
        border-radius: 5px;
        cursor: pointer;

        &:not(:last-child) {
          margin-bottom: 2px;
        }

        &:hover {
          background: $gray1;
        }
      }
    }
  }
}