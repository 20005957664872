@import 'items/add-items';

.subtitle {
  font-size: 1.5rem;
  margin: 1rem 2rem;
  line-height: 2rem;
  color: #707070;
  font-weight: 300;
  padding: 0 12px;
}

.item-variant-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  padding: 0 16px 12px;

  .items-variants {
    overflow-y: auto;
    padding: 0 10px 0 10px;
    width: 300px;
    max-height: 400px;
    min-height: 400px;
  
    .item-variant {
      height: 3rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1.25rem;
      padding: 4px;
      margin-left: 5px;
  
      span {
        flex: 1 1 auto;
      }
  
      &-selected {
        background-color: #fafafa;
        border-radius: 4px;
      }
    }
  }
  
  .variants {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 300px);
    padding: 0 20px 10px 20px;
    background: #fafafa;
    overflow-y: auto;
    max-height: 400px;
    min-height: 400px;
  
    .item-variant {
      height: 3rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 1.25rem;
      gap: 8px;
    }
  }
}

.items-variants-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  padding: 10px 16px;
}