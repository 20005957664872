.Select__menu {
    &-portal {
        min-width: 130px;
    }

    &-list {
        .Select__group:nth-child(1) {
            border-top: none !important;
        }

        .Select__option {
            &--is-selected {
                div>svg {
                    color: #30ABA9;
                }
            }
        }
    }

    &-list--is-multi {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;

        .Select__option {
            padding: 0;

            .custom-multiple-select {
                padding: 0.2rem 1.2rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;

                &-option {
                    display: flex;
                    align-items: center;
                    gap: 0.4rem;
                    min-height: 40px;
                }
            }
        }
    }
}