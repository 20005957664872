.dynamic-height {
    height: 60vh !important;
}

.network-message {
    gap: 6rem;
    height: calc(100vh - 5rem);

    &-body {
        display: flex;
        align-items: center;
        gap: 3rem;

        @include media-breakpoint-down("sm") {
            flex-direction: column;
            align-items: flex-start;
            gap: 0;
        }

        svg {
            margin-top: 3rem;

            @include media-breakpoint-down("sm") {
                margin-top: 0;
            }
        }

        &-info {
            display: flex;
            justify-content: center;
            flex-direction: column;

            h2 {
                font-size: 3.2rem;
                font-weight: 600;

                @include media-breakpoint-down("sm") {
                    font-size: 2.4rem;
                }
            }

            p {
                font-size: 1.4rem;
            }

            button {
                margin-top: 2.4rem;

                @include media-breakpoint-down("sm") {
                    width: 100%;
                }
            }

            button.button-menu-action {
                width: 204px;
                border-radius: 10px;
                padding: 8px 8px;
                font-size: 16px;

                @include media-breakpoint-down("sm") {
                    width: 100%;
                }
            }
        }
    }
}
