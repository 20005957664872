&.modal__update-spain-client {
  width: auto;
  border-radius: 16px !important;

  @include media-breakpoint-up('md') {
    width: 50rem;
  }

  @include media-breakpoint-down('md') {
    width: 50rem;
  }

  .modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5em 1.5em 0em 1.5em !important;
    border: none !important;
  }

  .modal__body {
    display: flex;
    flex-direction: column;
    padding: 0.5em 1.5em 1.5em 1.5em;
  }

  .modal__footer {
    margin-top: 3em;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @include media-breakpoint-down('md') {
      gap: 1em;
      flex-direction: column;
      align-items: stretch;
    }
  }

  .form-body {
    padding: 1.5em 0em 0em 0em !important;
  }
}
