&.modal__logout {
  label {
    @include font-size($h4-font-size);
  }

  .btn-submit {
    &.btn-danger {
      background: $danger !important;
      
      &:hover {
        &:not(:disabled) {
          background: $danger-dark !important;
        }
      }
      
      &:active {
        &:not(:disabled) {
          background: $danger-dark !important;
        }
      }
    }
  }
}