.form-body {
  .image-container {
    width: 20rem;
    aspect-ratio: 1;
    border: 2px $secondary dashed;

    img {
      object-fit: contain;
      -o-object-fit: contain;
    }
  }

}
.form__refund {
  &-divider {
    &-text {
      margin: 0 auto;
      width: fit-content;
      z-index: 1;
      background: $white;
      padding: 0 .5rem;
    }

    &-line {
      width: 100%;
      display: block;
      position: absolute;
      top: 50%;
    }
  }
}
.form-field-array-wrapper {
  max-height: calc(3.6rem * 8);
  overflow: hidden;
  overflow-y: auto;

  @include scrollbar(5px, $gray3);
}