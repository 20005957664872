#print {
	font-size: 14px !important;
	font-family: sans-serif !important;
	position: relative;
	display: none;
	padding-top: 1rem;
}

#print-invoice {

	p,
	h3,
	h4 {
		margin-bottom: .25rem;
		line-height: normal;

		.normal {
			margin-right: .5rem;

			&::after {
				content: ":";
			}
		}

		strong,
		.strong {
			font-weight: 600;
			margin-right: .5rem;

			&::after {
				content: ":";
				font-weight: 600;
			}
		}
	}

	strong::first-letter {
		text-transform: uppercase !important;
	}

	.divider {
		margin: .5rem 0;
		border-bottom: 1px solid $gray3;
	}

	img {
		display: block;
		width: 100%;
		aspect-ratio: 10/3;
	}

	.qr-info-panama {
		strong {
			&::after {
				content: "";
			}
		}
	}

	.border-top-dashed {
		border: 2px dashed black;
		border-style: dashed none none none;
	}

	.print-stamp-invoice {

		p,
		strong {
			font-size: 7px !important;
			line-break: anywhere !important;
			padding: 0 !important;
			margin: 0 !important;
		}
	}
}

#print-invoice,
#print-shift {
	font-size: 14px !important;
	display: block !important;
	color: #000 !important;
	--font-family-monospace: unset;
	--font-family-sans-serif: unset;

	&.print-80 {
		width: 80mm !important;
		font-size: 12px !important;
	}

	&.print-57 {
		width: 57mm !important;
		font-size: 10px !important;
	}

	p {
		font-size: 1em !important;
	}

	h3,
	.h3 {
		font-size: 1.5em;
	}

	h4,
	.h4 {
		font-size: 1.3em;
	}

	.items-table {
		*[class*='col-'] {
			padding: 0 !important;
		}

		* {
			font-size: .9em !important;
		}
	}

	.print-provisional-invoice-advice {
		margin-top: 15px;
		font-size: 11px;
		text-align: center;
		font-weight: lighter;
		line-height: 3.4em !important;
		color: #323232;
	}

	.DOM-items-table {
		*[class*='col-'] {
			padding: 0;
		}

		* {
			font-size: 8px !important;
		}
	}

	.electronic-info {
		* {
			font-size: .9em !important;
		}
	}

	*[class*='border'] {
		border-color: $title !important;
	}
}

@page: right {
	margin: 0;
}

@page: right {
	margin: 0;
}

@media print {

	header,
	footer {
		display: none;
	}

	html {
		font-size: 14px;

		html,
		body {
			display: none !important;
			margin: 0 !important;
		}

		>body {
			min-width: 0 !important;
			display: block !important;

			>* {
				display: none !important;
				margin: 0 !important;
				width: 0 !important;
				height: 0 !important;
			}
		}
	}

	.Toastify,
	.Toastify *,
	.navigation {
		width: 0 !important;
		display: none !important;
	}

	iframe {
		visibility: hidden !important;
	}

	#hubspot-messages-iframe-container {
		width: 0 !important;
		height: 0 !important;
		display: none !important;

		* {
			display: none !important;
		}

		iframe {
			visibility: hidden !important;
		}
	}

	#print {
		display: block !important;
	}
}

.container-text-flex {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}

.container-text-grid {
	display: grid;
	grid-template-columns: 80px 1fr;
	justify-content: flex-end;
	align-content: end;
	padding-left: 60px;
}

.invoice-print-tax-detail {
	font-size: 10px !important;

	&__header {
		font-size: 10px !important;
	}

	&__totals {
		font-size: 10px !important;
	}
}