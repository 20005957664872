.custom-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;

  $spinner-width: 8px;

  &-spinner {
    border-width: $spinner-width;
    border-style: solid;
    border-color: $primary $primary $primary transparent;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    animation: spin 1.5s infinite linear;
    position: relative;
    
    &:before, &:after {
      content: '';
      width: $spinner-width;
      height: $spinner-width;
      border-radius: 50%;
      background: $primary;
      position: absolute;
      left: 0;
    }
    
    &:before {
      top: 2px;
    }
    
    &:after {
      bottom: 2px;
    }

    @keyframes spin {
        to {
          transform: rotate(360deg);
        }
      }
  }

 
}