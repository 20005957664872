.new-seller {
  .form-body .form-group .input-number-wrapper,
  .company-form-body .form-group .input-number-wrapper {
    border-radius: 12px;
    border: 1px solid #cbd5e1;
    input {
      border-radius: 12px;
    }
  }

  .form-group {
    label {
      &.required {
        &::after {
          display: none;
        }
      }
    }
    label {
      &.required > p {
        &::after {
          content: ' *';
          color: $primary;
        }
      }
    }
  }
}
