.content {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1 1 100%;
  background: #f4f5fb;
  gap: 16px;

  .logo-pos {
    width: 48px;
    height: 48px;
  }

  .options {
    display: flex;
    gap: 16px;

    .option-card {
      border: 1px solid #CBD5E1;
      border-radius: 12px;

      &_content-image {
        background-color: #F1F5F9;
        border-radius: 12px;
        padding: 24px 9px 16px 9px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 240px;
          height: 140px;
        }
      }

      &_actions {
        padding: 16px 16px 24px;
        background-color: white;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .shape-plus {
    position: absolute;
    bottom: -190px;
    left: -140px;
    opacity: 0.32;
    transform: rotate(-30deg);;

    img {
      width: 450px;
      height: 450px;
    }
  }

  .shape-circle {
    position: absolute;
    top: -160px;
    right: -130px;
    opacity: 0.8;
    transform: rotate(-71deg);

    img {
      width: 450px;
      height: 450px;
    }
  }
}