.payment {

  .payment-type {
    width: 3.6rem;
    height: 3.6rem;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}