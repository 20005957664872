.popover-custom {
  display: inline-flex;
  position: relative;
  z-index: 100;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
 

  &>div {
    padding-right: 2.6rem;
    width: 38rem;
    position: absolute;
  }
}

.popover-custom-dark-body-notch-top {
  width: inherit;
  display: flex;
  padding: 1.6rem 1.6rem 2.4rem 1.6rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.8rem;
  align-self: stretch;
  border-radius: 0.8rem;
  color: white;
  background: var(--surface-inverse-slate-800, #1e293b);
  position: absolute;
  top: calc(100% + 1.5rem);

  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    right: 2.5rem;
    border-left: 1.4rem solid transparent;
    border-right: 1.4rem solid transparent;
    border-bottom: 1.4rem solid var(--surface-inverse-slate-800, #1e293b);
  }
}

.popover-custom-dark-body-notch-bottom {
  width: inherit;
  display: flex;
  padding: 1.6rem 1.6rem 2.4rem 1.6rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.8rem;
  align-self: stretch;
  border-radius: 0.8rem;
  color: white;
  background: var(--surface-inverse-slate-800, #1e293b);
  position: absolute;
  right: 0;
  bottom: calc(100% + 1.5rem);

  &::before {
    content: '';
    position: absolute;
    top: 100%;
    right: 2.5rem;
    border-left: 1.4rem solid transparent;
    border-right: 1.4rem solid transparent;
    border-top: 1.4rem solid var(--surface-inverse-slate-800, #1e293b);
  }
}

.popover-custom-dark-body-notch-right {
  width: inherit;
  display: flex;
  padding: 1.6rem 1.6rem 2.4rem 1.6rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.8rem;
  align-self: stretch;
  border-radius: 0.8rem;
  color: white;
  background: var(--surface-inverse-slate-800, #1e293b);
  position: absolute;
  right: calc(100% + 1.5rem);
  bottom: calc(100%);

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    border-top: 1.4rem solid transparent;
    border-bottom: 1.4rem solid transparent;
    border-left: 1.4rem solid var(--surface-inverse-slate-800, #1e293b);
    /* Change from 'border-right' to 'border-left' for right-pointing notch */
  }
}

.popover-custom-dark-body-notch-left {
  width: inherit;
  display: flex;
  padding: 1.6rem 1.6rem 2.4rem 1.6rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.8rem;
  align-self: stretch;
  border-radius: 0.8rem;
  color: white;
  background: var(--surface-inverse-slate-800, #1e293b);
  position: absolute;
  left: calc(100% + 1.5rem);
  bottom: calc(100%);

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    border-top: 1.4rem solid transparent;
    border-bottom: 1.4rem solid transparent;
    border-right: 1.4rem solid var(--surface-inverse-slate-800, #1e293b);
    /* Change from 'border-right' to 'border-left' for right-pointing notch */
  }
}

.popover-custom-orientation-left {
  left: calc(100% - 20rem);
  gap: 0.8rem;

  @include media-breakpoint-down('xs') {
    left: calc(100% - 10rem);

    &::before {
      right: calc(100% - 6.5rem);
    }
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    right: calc(100% - 4.5rem);
    border-left: 1.4rem solid transparent;
    border-right: 1.4rem solid transparent;
    border-bottom: 1.4rem solid var(--surface-inverse-slate-800, #1e293b);
  }
}

.popover-custom-orientation-right {
  left: 0;
  gap: 0.8rem;

  @include media-breakpoint-down('xs') {
    left: calc(100% - 20rem);

    &::before {
      display: none !important;
    }
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    right: calc(100% - 4.5rem);
    border-left: 1.4rem solid transparent;
    border-right: 1.4rem solid transparent;
    border-bottom: 1.4rem solid var(--surface-inverse-slate-800, #1e293b);
  }
}

.popover-custom-close-button {
  position: absolute !important;
  right: 0.8rem;
  top: 0.8rem;
}
