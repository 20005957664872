.settings {
  overflow: auto;
  @include scrollbar(5px, $primary);

  @import 'settings/print';
  @import 'settings/invoices';
}

.settings-menu {
  column-gap: 4rem;
  .tag-new {
    background: $primary;
    color: $white;
    padding: 3px 4px;
    border-radius: 11px;
    font-size: 9px;
    margin-bottom: 1rem;
  }

  &__option {
    flex: 0 0 22rem;
    min-height: 27rem;
    max-height: 27rem;
    background: $white;
    border-radius: 6px;
    border-bottom: 3px $primary solid;
    margin: 0 1rem 2rem;
    padding: 1rem;

    &-header {
      font-weight: 500;
      padding-bottom: .5rem;
      margin-bottom: .5rem;
      border-bottom: 1px solid $gray1;
    }

    &-description {
      font-size: 1.2rem;
      margin-bottom: .5rem;
      max-height: 6rem;
      min-height: 6rem;
    }

    &-options {
      font-size: 1.4rem;

      a:not(:last-child) {
        padding-bottom: .5rem;
      }

      a:hover {
        text-decoration: none;
      }
    }
  }
}

.table-actions {

  >* {
    &:not(:last-child) {
      margin-right: 5px;
    }
  }

  button {
    transition: border .2s ease;
    padding-left: 1px;
    padding-right: 1px;
    border: 1px $gray2 solid;

    svg {
      width: 22px;
      height: 22px;
    }

    &:hover:not(:disabled) {
      border-color: $primary;

      &.button-remove {
        border-color: $danger;
      }
    }

    &:disabled {

      color: $gray3 !important;

    }
  }
}

.tip-help-link {
  margin-right: 25px;
}

.regime-company {
  label {
    margin-bottom: 0;
  }
  p {
    font-family: Inter;
    color: rgb(112, 112, 112);
    font-size: 1.2rem;
    font-weight: 400;
  }

  span {
    color: #00b19d !important;
  }

  .s-input__input {
    font-family: Inter;
    color: rgb(112, 112, 112);
    font-size: 14px;
    font-weight: 400;
    border-radius: 0;
  }

  .s-input__container {
    margin-top: 0 !important;
    margin-bottom: 5px !important;
    border-radius: 5px;
  }
}

.settings-container {
  font-family: 'Inter';
  padding: 24px 64px;
  margin: 0 auto;
  margin-bottom: 200px;
  max-width: 1361px;


  .button-back-to-home-container {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;

    .btn {
      border-radius: 10px;
      padding: 5px 10px;
    }
  }

  .button-back-to-home {
    margin-top: 10px;
    border: 1px solid #80808059;
    border-radius: 10px;
    padding: 5px 10px;
    background: #80808000;
    transition: 0.25s ease-in-out;
  }

  .switch-tips.disabled {
    margin-top: 8px;
  }

  .tip-input {
    .h3 {
      margin: 0;
    }

    label {
      font-size: 14px;
      color: rgb(33, 37, 41);
      margin-right: 4px !important;
      margin-bottom: 2px !important;
    }
  }

  .button-back-to-home:not(:disabled):hover {
    background: #80808035;
  }

  @include media-breakpoint-down('sm') {
    padding: 24px 16px;
  }

  .view-header {
    h1 {
      font-size: 24px;
      font-family: 'Inter Semibold';
    }
  }

  .settings-body,
  .general-settings-body {
    border-radius: 16px;
    padding: 24px 40px;

    &-title {
      font-family: 'Inter Medium';
      font-size: 16px;
      margin: 0;

      &-disabled {
        margin: 0;
        color: #b7bac0;
      }
    }

    &-subtitle {
      font-size: 14px;
      margin-bottom: 14px;
      color: #6c757d;

      &-disabled {
        margin-bottom: 14px;
        color: #dde0e5;
        font-size: 14px;
      }
    }
  }

  .sell-preferences-body {
    &__multicurrency {
      padding: 24px 32px;
      border-radius: 16px;
      position: relative;

      .loading-text {
        position: absolute;
        top: 10px;
        right: 0;
      }
    }
  }

  .form-tips-settings {
    label {
      font-size: 14px;
    }

    .disabled {
      color: #b7bac0;
    }

    .input-tips-settings {
      input {
        border: 1px solid #d1d3d4 !important;
      }

      &::after {
        content: '%';
        position: absolute;
        right: 25px;
        top: 34px;
        transform: translateY(-50%);
        font-size: 14px;
        color: #6c757d;
      }

      @include media-breakpoint-down('sm') {
        &::after {
          top: 33px;
          right: 15px;
        }
      }

    }


  }
}