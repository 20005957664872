.invoice-notification {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 1rem 2rem 1rem 2rem;

  &-body {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 8px;
    gap: 8px;
    border-radius: 12px;
    border: 1px solid rgba(79, 70, 229, 0.2);
    background: #e0e7ff;

    p {
      color: #334155;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      padding: 0;
      margin: 0 .5rem 0 .5rem;
    }

    a {
      color: #4f46e5;
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
}
