.payment-method {
  &__card {
    background-color: $slate-50;
    width: 19rem;
    height: 19rem;
    border-radius: 0.5rem;
    border: solid 1px $slate-200;
    position: relative;

    &.integration {
      height: 22rem;
    }
  }

  &__favorite {
    right: 1.5rem;
    top: -2px;
    outline: none;
    box-shadow: none;
    height: 36px;
    width: 36px;
    z-index: 1;
    background: $primary;
    border-radius: 0 0 8px 8px !important;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);

    svg {
      height: 18px !important;
      path {
        stroke: $white;
        stroke-width: 2px;
      }

      &.item-favorite {
        fill: $white;
      }
    }
  }

  &__logo {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    & > img {
      width: 100%;
      height: 64px;
      object-fit: contain;
    }
  }

  &__icon {
    background-color: $slate-100;
    padding: 2rem;
    border-radius: 50%;
  }

  &__actions {
    border-top: solid 1px $slate-200;
    padding-top: 1rem;
  }

  &__container {
    gap: 1.5rem;
  }
  &__card_mobile {
    background-color: $slate-50;
    width: 100%;
    height: 13rem;
    border-radius: 0.5rem;
    border: solid 1px $slate-200;
    position: relative;
  }

  &__icon_mobile {
    background-color: $slate-100;
    padding: 1rem;
    transform: scale(0.7);
    border-radius: 50%;
    min-width: 45px;
  }
}

.integrations {
  &__container {
    gap: 1.5rem;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border: 1px solid $slate-300;
    border-radius: 0.5rem;
  }

  &__btn_mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border: 1px solid $primary;
    color: $primary-700;
    border-radius: 0.5rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  &__info {
    gap: 2rem;
  }
}

.reverse_mobile {
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  height: 8rem;
  padding-left: 1rem;
  width: 100%;
  gap: 1rem;
}

.payment-method-detail {
  &__notification {
    display: flex;
    gap: 1rem;
    border: 1px solid #4f46e533;
    border-radius: 12px;
    padding: 1.5rem 1.5rem;
    background-color: #e0e7fe;
  }
  &_name {
    &_container {
      border: #cbd5e1 1px solid;
      border-radius: 12px;
      background-color: #f8fafc;
      padding: 1rem 1rem;
    }
  }
  &_instructions {
    &_container {
      border: #cbd5e1 1px solid;
      border-radius: 12px;
      min-height: 8rem;
      padding: 0.5rem;
      background-color: #f8fafc;
    }
  }
}
